import { postApi, getApi } from "./utils";

export const createTestDrive = (selectedData) => {
  return postApi(`test_drives`, {
    customer_id: selectedData.customerId,
    phone: selectedData.phone,
    dealer_id: selectedData.dealerId,
    showroom_id: selectedData.showroomId,
    model_id: selectedData.ModelId,
    place: selectedData.place,
    date: selectedData.date,
  });
};
export const getTestDriveDetails = ({ id }) => {
  return getApi(`test_drives/${id}`);
};
