import React from "react";
import { Link } from "react-router-dom";
import BrandCard from "../../components/molecules/brandCard/BrandCard";
import { useSelector } from "react-redux";
import { selectBrands } from "../home/homeSlice";
import { Container } from "react-bootstrap";
import styles from "./brands.module.css";
const Brands = () => {
  const brands = useSelector(selectBrands);
  return (
    <Container className={styles.brandContainer}>
      <div className={styles.brands}>
        {brands.map((brand, index) => (
          <Link
            to={`/brand/${brand.id}/${brand.name
              .toLowerCase()
              .split(" ")
              .join("+")}`}
            key={`${brand.id}/${index}`}
          >
            <BrandCard brand={brand} />
          </Link>
        ))}
      </div>
    </Container>
  );
};

export default Brands;
