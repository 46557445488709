import React from "react";
import { useSelector } from "react-redux";
import { Container, Row, Alert, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { selectOrderData } from "../checkout/checkoutSlice";
import styles from "./styles.module.css";

function OrderSuccess() {
  const { booking } = useSelector(selectOrderData);
  return (
    <Container>
      <Row>
        <Col>
          <br />
          <Alert variant="success">
            <h1 className={styles.heading}>Congratulations !!</h1>
            <p> Your payment is successfull.</p>
            <p>
              Your {booking.model.name && booking.model.name} has been booked.
              Our dealer partner will contact you soon to asisst you further.
            </p>
            <p>Thank you for choosing Carbae.</p>
          </Alert>
          <Link to="/" className="nav_brand">
            <Button variant="light">Back to Home page</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default OrderSuccess;
