import React, { useState, useEffect, useRef, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectBanners,
  fetchBanners,
  selectModels,
  selectBrands,
  selectNewReleases,
  selectTopModels,
  selectElectricModels,
  bodyTypes,
} from "./homeSlice";

import { Col, Container, Row } from "react-bootstrap";

import styles from "./Home.module.css";
import ProdCard, {
  ProdCardSkelton,
} from "../../components/molecules/prodCard/ProdCard";
import BrandCard, {
  BrandCardSkelton,
} from "../../components/molecules/brandCard/BrandCard";
import { Heading } from "../../components/atoms/heading";
import SearchBar from "../SearchBar/SearchBar";

import Skeleton from "react-loading-skeleton";

import hatchback from "./SVG/HatchBack.svg";
import sedan from "./SVG/Sedan.svg";
import suv from "./SVG/Suv.svg";
import muv from "./SVG/muv.svg";
import luxuary from "./SVG/Luxuary.svg";
import minivan from "./SVG/minivan.svg";
import cuv from "./SVG/cuv.svg";
import pickup from "./SVG/pickup.svg";
import testdrive from "./SVG/testdrive.svg";

import { PriceRanges, BodyType } from "./data";

import Filter from "./Filter";
import { Link } from "react-router-dom";
import Banners from "../../components/molecules/Banners/Banners";
import Links from "../../components/molecules/Links/Links";
import { PriceRange } from "../../utils";
import { Helmet } from "react-helmet";

const Faq = React.lazy(() => import("./Faq"));
const Home = () => {
  const dispatch = useDispatch();

  const banners = useSelector(selectBanners);
  const models = useSelector(selectModels);
  const brands = useSelector(selectBrands);
  const bodyType = useSelector(bodyTypes);
  const topModels = useSelector(selectTopModels);
  const electricModels = useSelector(selectElectricModels);
  const newReleases = useSelector(selectNewReleases);
  const [show, setShow] = useState(false);
  const [body_type, setBody_type] = useState("");
  const [brand_id, setBrand_id] = useState("");
  const [budget, setBudget] = useState("");
  const [filteredTopCars, setFilteredTopCars] = useState("");
  const [selectedTopCars, setSelectedTopCars] = useState("hatchback");
  const [itemKey, setItemKey] = useState("");

  const Logo = {
    suv,
    hatchback,
    luxuary,
    sedan,
    muv,
    minivan,
    cuv,
    pickup,
  };

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  useEffect(() => {
    setFilteredTopCars(
      topModels
        .filter((models) => models.body_type === "hatchback")
        .sort((a, b) =>
          a.price_starts > b.price_starts
            ? 1
            : b.price_starts > a.price_starts
            ? -1
            : 0
        )
    );
    setSelectedTopCars("hatchback");
  }, [topModels]);

  const TypeHandler = (datasource, SetDataSource, type) => {
    if (datasource.indexOf(type) !== -1) {
      SetDataSource(datasource.filter((value) => value !== type));
    } else {
      SetDataSource((brandId) => [...brandId, type]);
    }
  };

  const BodyHandlerHome = (type) => {
    TypeHandler(body_type, setBody_type, type);
    setShow(true);
  };

  const BudgetHandlerHome = (key) => {
    setBudget(key);
    setItemKey("0");
    setShow(true);
  };
  const clearFilter = () => {
    setBody_type("");
    setBrand_id("");
    setBudget("");
    setShow(false);
  };
  const ref = useRef(null);
  const Newref = useRef(null);
  const Electricref = useRef(null);
  const scroll = (scrollOffset, ref) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [Newprev, setNewPrev] = useState(false);
  const [Newnext, setNewNext] = useState(true);
  const [Electricprev, setElectricPrev] = useState(false);
  const [Electricnext, setElectricNext] = useState(true);
  const Scroll = (ref, next, prev) => {
    if (ref) {
      const { scrollLeft, scrollWidth, clientWidth } = ref.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        next(false);
      }
      if (scrollLeft + clientWidth < scrollWidth) {
        next(true);
      }
      if (scrollLeft > 0) {
        prev(true);
      }
      if (scrollLeft === 0) {
        prev(false);
      }
    }
  };

  const BodyFilter = (models, type) => {
    const result = models
      .filter((model) => model.body_type === type)
      .sort((a, b) =>
        a.price_starts > b.price_starts
          ? 1
          : b.price_starts > a.price_starts
          ? -1
          : 0
      );
    setFilteredTopCars(result);
    setSelectedTopCars(type);
  };
  return (
    <>
      <Container className={styles.home}>
        {!models.length && (
          <>
            <Row>
              <Col md="4">
                <Heading>
                  <p>Loading...</p>
                  <Skeleton />
                </Heading>
              </Col>
            </Row>
            <Row className={styles.models}>
              <Col xs="6" sm="4" className={styles.brand}>
                <ProdCardSkelton />
              </Col>
              <Col xs="6" sm="4" className={styles.brand}>
                <ProdCardSkelton />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className={styles.brand}>
                <BrandCardSkelton />
              </Col>
              <Col xs="6" className={styles.brand}>
                <BrandCardSkelton />
              </Col>
              <Col xs="6" className={styles.brand}>
                <BrandCardSkelton />
              </Col>
              <Col xs="6" className={styles.brand}>
                <BrandCardSkelton />
              </Col>
            </Row>
          </>
        )}
        {models.length > 0 && (
          <>
            <Helmet>
              <script type="application/ld+json">
                {JSON.stringify({
                  // "@context": "http://schema.org/",
                  // "@type": "AutomotiveBusiness",
                  // name: "CARBAE",
                  // url: "https://www.carbae.in/",
                  // logo: logo,
                  // description:
                  //   "Carbae.in - Welcome To The New Way To Buy New Cars. Explore Top Car Brands, Find On-Road Prices & Latest Offers. Book Test Drives Online",

                  // sameAs: [
                  //   "https://www.facebook.com/carbae.in/",
                  //   "https://www.instagram.com/carbae.in_/",
                  //   "https://twitter.com/Carbae_in/",
                  // ],

                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  mainEntity: [
                    {
                      "@type": "Question",
                      name: "How does Carbae help me find the accurate on-road price of cars near me?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p>At Carbae, we revolutionize the car buying experience by enabling you to find accurate on-road prices of cars near you with just a few clicks. Our platform aggregates real-time data from authorized dealerships, ensuring that you have access to the most up-to-date pricing information and exclusive offers. No more showroom visits or endless phone calls - Carbae makes it effortless to compare prices, grab the latest deals, and make informed decisions. Experience the convenience of finding accurate on-road prices at your fingertips with Carbae.</p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "Can I book a test drive through Carbae? How does it work?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p>Certainly! With Carbae, booking a test drive for your favorite car is quick and hassle-free. Simply follow these steps:</p><ol><li>Visit the Carbae website or mobile app.</li><li>Browse through our wide range of available car models and select the one you're interested in.</li> <li> On the car's details page, you'll find a Book Test Drive button. Click on it. </li> <li> Fill in your contact details and preferred date and time for the test drive. </li> <li> Submit your request, and Carbae will instantly notify the nearest dealerships offering the selected car model </li> </ol> <p> Sit back and relax! The dealership will contact you to confirm the test drive appointment and provide further instructions. </p> <p> By using Carbae, you can conveniently schedule a test drive without the need for multiple phone calls or showroom visits. It saves you time and ensures a seamless experience. Start exploring and book your test drive with Carbae today! </p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "What are the latest offers and discounts available on my favorite car models?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p> At Carbae, we keep you updated with the latest offers and discounts on your favorite car models. Here's how you can find out about them: </p> <ol> <li>Visit the Carbae website</li> <li> Navigate to the Offers section or look for the Latest Offers tab. </li> <li> Browse through the available offers, which are regularly updated. </li> <li> Filter the offers based on your preferred car brand, model, or location to find the ones that match your interests. </li> <li> Click on the offer to view more details, including any specific terms and conditions. </li> <li> If you find an offer that suits your requirements, you can contact the respective dealership directly through Carbae for further assistance or to avail of the offer. </li> </ol> <p> By using Carbae, you can conveniently explore the latest offers and discounts on your favorite car models in one place. Don't miss out on the opportunity to grab exciting deals and make your car buying experience even more rewarding. Start discovering the latest offers on Carbae today! </p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "How can Carbae save me time and effort during the car buying process?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p> Carbae is designed to save you time and effort during the car buying process. Here's how: </p> <ul> <li> Comprehensive Information: Carbae provides you with detailed information about various car models, including specifications, features, and pricing. This helps you make informed decisions without the need for extensive research or multiple visits to different showrooms. </li> <li> On-Road Price Comparison: With Carbae, you can easily compare on-road prices of cars from multiple dealerships near you. This saves you the hassle of individually contacting each showroom or visiting them physically to gather price information. </li> <li> Test Drive Booking: Carbae offers the convenience of booking test drives directly through the platform. Simply select your preferred car model, choose a convenient date and time, and book your test drive. This eliminates the need for multiple phone calls or showroom visits to schedule test drives. </li> <li> Exclusive Offers: Carbae keeps you updated with the latest offers and discounts on your favorite car models. By accessing these offers through Carbae, you can save time scouring multiple sources for deals and promotions. </li> <li> Streamlined Communication: Carbae acts as a centralized platform for communication between you and the car dealerships. You can connect with dealerships, inquire about specific cars, negotiate prices, and finalize your purchase, all within the Carbae platform. </li> </ul> <p> By leveraging the features and services provided by Carbae, you can streamline your car buying journey, saving valuable time and effort. Focus on finding the right car for your needs while Carbae takes care of the logistics and simplifies the process for you. </p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "Is Carbae affiliated with authorized dealerships? Are the prices and offers on Carbae genuine?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p> Yes, Carbae is affiliated with authorized dealerships of various car brands. We partner with reputable and trusted dealerships to ensure that the prices and offers displayed on our platform are genuine and accurate. </p> <p> We understand the importance of providing reliable information to our users, which is why we work closely with authorized dealerships who adhere to industry standards and pricing guidelines. The prices you see on Carbae reflect the on-road prices provided by these dealerships, including the ex-showroom price, applicable taxes, registration fees, and other charges. </p> <p> The offers and discounts featured on Carbae are also genuine and provided directly by the dealerships. We strive to keep our platform updated with the latest promotions and special deals offered by the dealerships, so you can take advantage of the best offers available </p> <p> At Carbae, our commitment is to provide you with transparent and accurate information, ensuring a trustworthy and reliable car buying experience. You can have confidence in the prices and offers displayed on our platform, as they come directly from authorized dealerships. </p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "Can I customize my search criteria on Carbae, such as budget, fuel type, or specific features?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p> Yes, on Carbae, you have the flexibility to customize your search criteria to find the perfect car that meets your preferences. Our platform allows you to refine your search based on various parameters, including: </p> <ul> <li> Budget: You can set your desired budget range to view cars within your price range. </li> <li> Fuel Type: If you have a preference for a specific fuel type, such as petrol, diesel, or electric, you can select your preferred fuel type to narrow down your search results accordingly. </li> <li> Specific Features: Carbae provides the option to specify certain features or characteristics you are looking for in a car, such as automatic transmission, seating capacity, safety features, infotainment system, and more </li> </ul> <p> By customizing your search criteria, you can quickly and efficiently find cars that match your requirements and preferences. Carbae's user-friendly interface and advanced search filters make it convenient to refine your search and explore a wide range of options that best suit your needs. </p>",
                      },
                    },
                    {
                      "@type": "Question",
                      name: "How can I compare different car models on Carbae?",
                      acceptedAnswer: {
                        "@type": "Answer",
                        text: "<p> Comparing different car models on Carbae is easy and convenient. Here's how you can do it: </p> <ol> <li> Visit the Carbae website or app and navigate to the search section. </li> <li> Enter the specific car models you want to compare in the search bar. </li> <li> You will be presented with a list of car models matching your search criteria. </li> <li> Select the car models you wish to compare by clicking on them. </li> <li> On the car model pages, you will find detailed information about each model, including specifications, features, on-road prices, and more. </li> <li> To compare the selected models, look for the Compare button or feature on the page. Click on it. </li> <li> A side-by-side comparison view will be displayed, allowing you to compare the key features, specifications, prices, and other relevant information of the chosen car models. </li> <li> Analyze the compared features and make an informed decision based on your preferences and requirements </li> </ol> <p> Carbae enables you to efficiently compare multiple car models, helping you make a well-informed choice that best suits your needs. </p>",
                      },
                    },
                  ],
                })}
              </script>
            </Helmet>
            <SearchBar
              placeholder="Search your car"
              data={models}
              testdrive={false}
            />
          </>
        )}
        {banners.length > 0 && <Banners banners={banners} />}
        {topModels.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>Top Cars</Heading>
            </div>
            <div className={styles.topCarList}>
              <div
                className={`${styles.topcarbody} ${
                  selectedTopCars === "hatchback" && styles.selectedTopcar
                }`}
                onClick={() => BodyFilter(topModels, "hatchback")}
              >
                HatchBack
              </div>
              <div
                className={`${styles.topcarbody} ${
                  selectedTopCars === "sedan" && styles.selectedTopcar
                }`}
                onClick={() => BodyFilter(topModels, "sedan")}
              >
                Sedan
              </div>
              <div
                className={`${styles.topcarbody} ${
                  selectedTopCars === "suv" && styles.selectedTopcar
                }`}
                onClick={() => BodyFilter(topModels, "suv")}
              >
                SUV
              </div>
            </div>
            <div className={styles.scrollContainer}>
              {prev && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonPrev}`}
                  onClick={() => scroll(-150, ref)}
                ></div>
              )}
              <div
                className={styles["scrolling-wrapper"]}
                onLoad={() => Scroll(ref, setNext, setPrev)}
                onScroll={() => Scroll(ref, setNext, setPrev)}
                ref={ref}
              >
                {filteredTopCars &&
                  filteredTopCars.map((product, index) => (
                    <div className={styles.card} key={`${product.id}/${index}`}>
                      <ProdCard product={product} />
                    </div>
                  ))}
              </div>
              {next && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonNext}`}
                  onClick={() => scroll(150, ref)}
                ></div>
              )}
            </div>
          </>
        )}
        {newReleases.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>New Releases</Heading>
            </div>
            <div className={styles.scrollContainer}>
              {Newprev && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonPrev}`}
                  onClick={() => scroll(-150, Newref)}
                ></div>
              )}
              <div
                className={styles["scrolling-wrapper"]}
                onScroll={() => Scroll(Newref, setNewNext, setNewPrev)}
                ref={Newref}
              >
                {newReleases &&
                  newReleases.map((product, index) => (
                    <div className={styles.card} key={`${product.id}/${index}`}>
                      <ProdCard product={product} />
                    </div>
                  ))}
              </div>
              {Newnext && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonNext}`}
                  onClick={() => scroll(150, Newref)}
                ></div>
              )}
            </div>
          </>
        )}
        {electricModels.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>Electric Cars</Heading>
            </div>
            <div className={styles.scrollContainer}>
              {Electricprev && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonPrev}`}
                  onClick={() => scroll(-150, Electricref)}
                ></div>
              )}
              <div
                className={styles["scrolling-wrapper"]}
                onScroll={() =>
                  Scroll(Electricref, setElectricNext, setElectricPrev)
                }
                ref={Electricref}
              >
                {electricModels &&
                  electricModels.map((product, index) => (
                    <div className={styles.card} key={`${product.id}/${index}`}>
                      <ProdCard product={product} />
                    </div>
                  ))}
              </div>
              {Electricnext && (
                <div
                  className={`${styles.scrollButton} ${styles.scrollButtonNext}`}
                  onClick={() => scroll(150, Electricref)}
                ></div>
              )}
            </div>
          </>
        )}
        {models.length > 0 && (
          <div className={styles.testdrive}>
            <div>
              <h3 className={styles.testdriveheading}>
                Schedule your Test drives here
              </h3>
              <SearchBar
                placeholder="Enter car name "
                data={models}
                testdrive={true}
              />
            </div>
            <img
              src={testdrive}
              alt="testdrive"
              className={styles.testdriveimg}
              loading="lazy"
            />
          </div>
        )}
        {brands.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>Search by Brand </Heading>
            </div>
            <div className={styles.brands}>
              {brands.map((brand, index) => (
                <Link
                  to={`/brand/${brand.id}/${brand.name
                    .toLowerCase()
                    .split(" ")
                    .join("+")}`}
                  className={styles.brand}
                  aria-label={`View more about ${brand.name}`}
                  key={`${brand.id}/${index}`}
                >
                  <BrandCard brand={brand} />
                </Link>
              ))}
            </div>
          </>
        )}
        {bodyType.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>Search by Type</Heading>
            </div>
            <div className={styles.bodyBox}>
              {bodyType.map((type) => (
                <div
                  key={type}
                  className={styles.bodyType}
                  onClick={() => {
                    BodyHandlerHome(type);
                    setItemKey("2");
                  }}
                >
                  <img
                    src={Logo[type]}
                    alt={type}
                    className={styles.type}
                    loading="lazy"
                  />
                  <p className={styles.bodyname}>{BodyType[type]}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {models.length > 0 && (
          <>
            <div className={styles.heading}>
              <Heading>Search by Price Range</Heading>
            </div>
            <div className={styles.bodyBox}>
              {PriceRanges.map((key, index) => (
                <div
                  key={index}
                  className={styles.priceType}
                  onClick={() => BudgetHandlerHome(key.name)}
                >
                  <p className={styles.priceName}>
                    {PriceRange(key.name.start, key.name.end, 1)}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
        <div className={styles.contentBox}>
          <div className={styles.heading}>
            <h1>Carbae.in</h1>
          </div>
          <p>
            Welcome to Carbae.in, the ultimate destination for new car buyers
            and dealerships. Discover a revolutionary car buying experience that
            offers accurate on-road prices, latest offers, and convenient test
            drive bookings. Find the perfect car and unlock exclusive deals from
            trusted dealers near you. With Carbae.in, you can explore a wide
            range of vehicles, compare prices, and make informed decisions.
            Experience the convenience of finding the best on-road prices and
            grabbing the latest offers on your favorite cars. Book your test
            drive effortlessly through Carbae.in and embark on a seamless car
            buying journey. Trust Carbae.in for accurate prices, exclusive
            offers, and unmatched convenience. Start your car buying experience
            today!
          </p>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Faq />
        </Suspense>{" "}
      </Container>
      {show && (
        <Filter
          visibility={show}
          brand={brand_id}
          body={body_type}
          bud={budget}
          itemKey={itemKey}
          click={() => clearFilter()}
        />
      )}
      {brands && models && <Links brands={brands} models={models} />}
    </>
  );
};
export default Home;
