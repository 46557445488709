import { createSlice } from "@reduxjs/toolkit";
import { createTestDrive, getTestDriveDetails } from "../../api/testdrive";
import { logout } from "../login/loginSlice";

export const slice = createSlice({
  name: "testdrive",
  initialState: {
    data: {},
    details: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
    },
    selected: {
      dealerId: null,
      showroomId: null,
      place: null,
      date: null,
      customerId: null,
      phone: null,
    },
  },
  reducers: {
    orderCreateSuccess: (state, action) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.order = action.payload;
    },
    orderCreateFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
      state.order = {};
    },
    DeatilsCreateSuccess: (state, action) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.details = action.payload;
    },
    DetailsCreateFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
      state.details = {};
    },
    orderReset: (state) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.order = {};
    },
    modelSelected: (state, action) => {
      state.selected[action.payload.type] = action.payload.data;
    },
    modelReplaceActive: (state, action) => {
      state.selected = {
        ModelId: action.payload,
      };
    },
  },
});

const {
  modelSelected,
  modelReplaceActive,
  orderCreateSuccess,
  orderCreateFailed,
  DeatilsCreateSuccess,
  DetailsCreateFailed,
  orderReset,
} = slice.actions;

export const selectOrderData = (state) => state.testdrive.order || {};
export const selectDeatailData = (state) => state.testdrive.details || {};
export const selectActive = (state) => state.testdrive.selected || {};
export const selectCheckoutError = (state) => state.testdrive.error || {};

export const createOrderAPI = (selectedData, callback) => async (dispatch) => {
  try {
    const data = await createTestDrive(selectedData);
    dispatch(orderCreateSuccess(data));
    callback(`/testdrive-success`);
  } catch (err) {
    dispatch(logout());
    dispatch(
      orderCreateFailed({
        message: err,
      })
    );
  }
};
export const loadTestDrive =
  (id = "") =>
  async (dispatch) => {
    try {
      const data = await getTestDriveDetails({ id });
      dispatch(DeatilsCreateSuccess(data));
    } catch (err) {
      dispatch(DetailsCreateFailed(err.toString()));
    }
  };
export const resetOrderData = () => async (dispatch) => {
  dispatch(orderReset());
};

export const setActiveModelTestDrive = (data) => async (dispatch) => {
  if (data) dispatch(modelSelected(data));
};

export const resetActiveModel = (id) => async (dispatch) => {
  dispatch(modelReplaceActive(id));
};

export default slice.reducer;
