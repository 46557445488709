import React from "react";
import { useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { loadModelFromPreloadedData } from "../../../features/model/modelSlice";
import Image, {
  ImageSkelton,
  ImageWithoutWrapper,
} from "../../atoms/Image/Image";

import styles from "./prodCard.module.css";
import { changeNumberFormat, PriceRange } from "../../../utils";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const ProdCard = ({ product = {}, ...props }) => {
  const dispatch = useDispatch();
  const preloadProduct = () => {
    dispatch(loadModelFromPreloadedData(product));
  };

  return (
    <Link to={`/${product.slug}`} className={styles.card}>
      <Card {...props}>
        <Image
          variant="top"
          src={product.main_image}
          loading="lazy"
          alt={product.name}
        />
        <Card.Body>
          <h2 className={styles.modelName}>{product.name}</h2>
          <Card.Title>{changeNumberFormat(product.price_starts, 2)}</Card.Title>
          <Link className={styles.anchorTag} to={`/${product.slug}`}>
            <Button variant="primary" onClick={preloadProduct} block>
              Book
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </Link>
  );
};

export const ProductCard = ({ product = {}, ...props }) => {
  const dispatch = useDispatch();
  const preloadProduct = () => {
    dispatch(loadModelFromPreloadedData(product));
  };

  return (
    <Link
      className={styles.row}
      to={`/${product.slug}`}
      onClick={preloadProduct}
    >
      <div className={styles["col-40"]}>
        <ImageWithoutWrapper
          variant="top"
          src={product.main_image}
          loading="lazy"
          alt={product.name}
        />
      </div>
      <div className={styles["col-60"]}>
        <h2 className={styles.name}>{product.name}</h2>
        <p className={styles.price}>
          {PriceRange(product.price_starts, product.price_ends, 1)}
        </p>
        {/* {product.description.match(/\d+/g) ? (
          <p className={styles.blur}>
            {!(product.description.match(/\d+/g).map(Number)[0] === 5000) ? (
              `Offers Upto : ₹
              ${product.description.match(/\d+/g).map(Number)[0]}`
            ) : (
              <br />
            )}
          </p>
        ) : (
          <br />
        )} */}
      </div>
    </Link>
  );
};

export const ProdCardSkelton = (props) => {
  return (
    <div className={styles.card}>
      <Card {...props}>
        <ImageSkelton variant="top" />
        <Card.Body>
          <Card.Text>
            <Skeleton />
          </Card.Text>
          <Card.Title>
            <Skeleton />
          </Card.Title>
          <Button variant="outl" block>
            <Skeleton />
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default ProdCard;
