import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Form, Button } from "react-bootstrap";

import { useHistory } from "react-router-dom";

import styles from "./Login.module.css";
import {
  sentOTPToUser,
  selectWaitingForOTP,
  verifyOTPFromUser,
  selectUserData,
  selectLoginErr,
} from "./loginSlice";
import { Formik } from "formik";
import { validateOTP, validateMobileEmailForm } from "./login_utils";
import get from "lodash/get";
import { useQuery } from "../../api/utils";
const Login = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const redirectTo = useQuery("redirectTo") || "/";
  const [userPhone, setUserPhone] = useState(null);
  const isWaitingForOTP = useSelector(selectWaitingForOTP);
  const loginErr = useSelector(selectLoginErr);

  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (get(userData, ["session", "access-token"]) && isMountedRef.current) {
      history.push(redirectTo);
    }
  }, [userData, history, redirectTo]);

  const getOtp = (values = {}, { setSubmitting }) => {
    setSubmitting(true);
    setUserPhone(values.mobile);
    dispatch(sentOTPToUser(values, setSubmitting));
  };

  const verifyOtp = (values = {}, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      verifyOTPFromUser({ ...values, phone: userPhone }, setSubmitting, () => {
        if (isMountedRef.current) {
          history.push(redirectTo);
        }
      })
    );
  };
  const getOTPForm = () => (
    <Formik
      initialValues={{ otp: "" }}
      validate={validateOTP}
      onSubmit={verifyOtp}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form className={styles.login} onSubmit={handleSubmit}>
          <Form.Group controlId="otp">
            <Form.Label>OTP :</Form.Label>
            <Form.Control
              autoComplete="false"
              type="text"
              name="otp"
              placeholder="Enter the OTP "
              onChange={handleChange}
              value={values.otp || ""}
            />
            <div className={styles.error}>
              {errors.otp && touched.otp && errors.otp}
            </div>
            <div className={styles.error}>
              {loginErr.haveError ? "Please try again" : ""}
            </div>
          </Form.Group>
          <div className={styles.btn}>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Verifying OTP..." : "Verify"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
  const getMobileForm = () => (
    <Formik
      initialValues={{ email: "", mobile: "", firstName: "", lastName: "" }}
      validate={validateMobileEmailForm}
      onSubmit={getOtp}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form className={`${styles.login}`} onSubmit={handleSubmit}>
          <Form.Group controlId="firstName">
            <Form.Label> Name </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your  Name"
              onChange={handleChange}
              value={values.firstName}
            />
            <div className={styles.error}>
              {errors.firstName && touched.firstName && errors.firstName}
            </div>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email </Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter Your Email"
              onChange={handleChange}
              value={values.email}
            />
            <div className={styles.error}>
              {errors.email && touched.email && errors.email}
            </div>
          </Form.Group>
          <Form.Group controlId="mobile">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="tel"
              name="mobile"
              placeholder="Enter Your Mobile Number"
              onChange={handleChange}
              value={values.mobile}
            />
            <div className={styles.error}>
              {errors.mobile && touched.mobile && errors.mobile}
            </div>
            <Form.Text className="muted">
              We'll send an OTP to this number.
            </Form.Text>
          </Form.Group>
          <div className={styles.btn}>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Getting OTP..." : "Get OTP"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
  return (
    <Container>{!isWaitingForOTP ? getMobileForm() : getOTPForm()}</Container>
  );
};
export default Login;
