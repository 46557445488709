import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Alert, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  loadTestDrive,
  selectOrderData,
  selectDeatailData,
} from "../testdrive/testDriveSlice";
import { useEffect } from "react";
// import styles from "./styles.module.css";

function OrderSuccess() {
  const dispatch = useDispatch();
  const booking = useSelector(selectOrderData);
  const details = useSelector(selectDeatailData);
  useEffect(() => {
    dispatch(loadTestDrive(booking.id));
  }, [dispatch, booking]);
  return (
    <Container>
      {booking.id && details.id && (
        <Row>
          <Col>
            <br />
            <Alert variant="success">
              <p>
                Hi {details.customer.first_name && details.customer.first_name},
              </p>
              <p>
                {" "}
                Test drive for {details.model.name} is booked successfully.
              </p>
              <p>
                Our dealer partner will contact you soon to asisst you further.
              </p>
              <p>Thank you for choosing Carbae.</p>
            </Alert>
            <Link to="/" className="nav_brand">
              <Button variant="light">Back to Home page</Button>
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default OrderSuccess;
