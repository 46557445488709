import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { selectUserData } from "../login/loginSlice";
import get from "lodash/get";
import styles from "./styles.module.css";

const Profile = () => {
  const userData = useSelector(selectUserData);
  const [session, setSession] = useState("");

  const isLogin = useCallback(() => {
    if (get(userData, ["session", "access-token"])) {
      return true;
    }
    return false;
  }, [userData]);

  useEffect(() => {
    isLogin() && setSession(userData.userInfo);
  }, [isLogin, userData.userInfo]);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Container>
      {isLogin() ? (
        <div className={styles.profile}>
          <h3 className="center">{`Welcome ${
            session.first_name && capitalize(session.first_name)
          } ${session.last_name && capitalize(session.last_name)} `}</h3>
          <p className="center">We are working on this page</p>
        </div>
      ) : (
        "Login To See Your Profile"
      )}
    </Container>
  );
};
export default Profile;
