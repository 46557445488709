import Axios from "axios";
import get from "lodash/get";
import { load } from "redux-localstorage-simple";
import { useLocation } from "react-router-dom";

const API_ROOT = process.env.REACT_APP_API_ENDPOINT;
let useSessionSet = false;
const initUserSession = () => {
  const userSession = get(load({ states: ["user"] }), [
    "user",
    "data",
    "session",
  ]);
  if (userSession) {
    useSessionSet = true;
    Axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userSession["access-token"]}`;
    Axios.defaults.headers.common["access-token"] = userSession["access-token"];
    Axios.defaults.headers.common["client"] = userSession["client"];
    Axios.defaults.headers.common["token-type"] = userSession["token-type"];
    Axios.defaults.headers.common["uid"] = userSession["uid"];
  }
};
initUserSession();
export const SERVER_HOST = process.env.REACT_APP_API_BASE_ENDPOINT;

export const getApi = (url, params = {}, fallback = {}) => {
  return Axios.get(`${API_ROOT}/${url}.json`, { params }).then((res) => {
    return get(res, ["data", "data"], fallback);
  });
};

export const getApiResponse = (url, params = {}, fallback = {}) => {
  return Axios.get(`${API_ROOT}/${url}.json`, { params }).then((res) => {
    return get(res, ["data"], fallback);
  });
};

export const postApi = (url, params = {}, fallback = {}) => {
  if (!useSessionSet) {
    initUserSession();
  }
  return Axios.post(`${API_ROOT}/${url}.json`, params)
    .then((res) => {
      return get(res, ["data", "data"], fallback);
    })
    .catch((err) => {
      return Promise.reject(get(err.response, ["data", "errors", 0]));
    });
};

export const updateApi = (url, params = {}, fallback = {}) => {
  if (!useSessionSet) {
    initUserSession();
  }
  return Axios.put(`${API_ROOT}/${url}.json`, params)
    .then((res) => {
      return get(res, ["data", "data"], fallback);
    })
    .catch((err) => {
      return Promise.reject(get(err.response, ["data", "errors", 0]));
    });
};

export const postApiClean = (url, params = {}, fallback = {}) => {
  if (!useSessionSet) {
    initUserSession();
  }
  return Axios.post(`${API_ROOT}/${url}.json`, params).then((res) => {
    return res;
  });
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};
