import { postApi } from "./utils";

export const createLead = (selectedData) => {
  return postApi(`leads`, selectedData);
};

export const financeDeatils = (selectedData) => {
  return postApi(`finances/validate`, selectedData);
};

export const financeLead = (selectedData) => {
  return postApi(`finances/submit_request`, selectedData);
};
