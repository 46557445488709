export const filterArray = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores non-function predicates
      if (!filters[key].length) {
        return true;
      }
      return filters[key].includes(item[key]);
    });
  });
};

export const Filter = (array, filters, budget) => {
  const filteredArray = filterArray(array, filters);
  const Result = filteredArray.filter((product) => {
    const startPrice = product.price_starts;
    const lastPrice = product.price_ends;

    if (budget.start && budget.end) {
      return (startPrice >= budget.start && startPrice <= budget.end) ||
        (lastPrice >= budget.start && lastPrice <= budget.end)
        ? product
        : undefined;
    } else return product;
  });
  return Result;
};
