import { createSlice } from "@reduxjs/toolkit";
import { Filter } from "./Utils";

export const slice = createSlice({
  name: "filter",
  initialState: {
    body_type: "",
    brand_id: "",
    budget: "",
    transmission_types: "",
    fuel_types: "",
    results: [],
    loadingResults: false,
    error: {
      message: "",
    },
  },
  reducers: {
    setLoading: (state) => {
      state.loadingResults = true;
    },
    getResultssSuccess: (state, action) => {
      state.results = action.payload;
      state.loadingResults = false;
      state.error = {};
    },
    getResultsFailed: (state) => {
      state.results = [];
      state.loadingResults = false;
      state.error.message = "Something went wrong";
    },
    BodySelected: (state, action) => {
      state.body_type = action.payload;
    },
    BrandSelected: (state, action) => {
      state.brand_id = action.payload;
    },
    BudgetSelected: (state, action) => {
      state.budget = action.payload;
    },
    TransmissionTypeSelected: (state, action) => {
      state.transmission_types = action.payload;
    },
    FuelTypeSelected: (state, action) => {
      state.fuel_types = action.payload;
    },
  },
});

export const selectResults = (state) => state.filter.results || [];
export const selectLoading = (state) => state.filter.loadingResults;
export const selectBody_type = (state) => state.filter.body_type;
export const selectBrand_id = (state) => state.filter.brand_id;
export const selectBudget = (state) => state.filter.budget;
export const selectTransmission_types = (state) =>
  state.filter.transmission_types;
export const selectFuel_types = (state) => state.filter.fuel_types;

export const {
  setLoading,
  getResultssSuccess,
  getResultsFailed,
  BodySelected,
  BrandSelected,
  BudgetSelected,
  TransmissionTypeSelected,
  FuelTypeSelected,
} = slice.actions;

export const fetchResults = (array, filters, budget) => async (dispatch) => {
  try {
    const results = await Filter(array, filters, budget);
    dispatch(getResultssSuccess(results));
  } catch (err) {
    dispatch(getResultsFailed(err.toString()));
  }
};

export const setBody_type = (data) => async (dispatch) => {
  dispatch(BodySelected(data));
};

export const setBrand_id = (data) => async (dispatch) => {
  dispatch(BrandSelected(data));
};

export const setBudget = (data) => async (dispatch) => {
  dispatch(BudgetSelected(data));
};

export const setTransmission_types = (data) => async (dispatch) => {
  dispatch(TransmissionTypeSelected(data));
};

export const setFuel_types = (data) => async (dispatch) => {
  dispatch(FuelTypeSelected(data));
};

export default slice.reducer;
