import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Links = ({ brands, models }) => {
  // const [itemsToShow, setItemsToShow] = useState(5);
  // const showmore = (list) => {
  //   setItemsToShow(list.length);
  // };

  // const showless = () => {
  //   setItemsToShow(5);
  // };
  return (
    <div className={styles.linkContainer}>
      <div className={styles.box}>
        <h3>Popular Car Brands</h3>
        {brands.map((brand, index) => (
          <Link
            to={`/brand/${brand.id}/${brand.name
              .toLowerCase()
              .split(" ")
              .join("+")}`}
            key={`${brand.id}/${index}`}
          >
            {brand.name}
          </Link>
        ))}
      </div>

      <div className={styles.box}>
        {/* {models.slice(0, itemsToShow).map((model, index) => ( */}
        <h3>Book New Cars Test Drives Online</h3>
        {models.map((model, index) => (
          <Link to={`/${model.slug}/testdrive`} key={`${model.id}/${index}`}>
            Test drive {model.name} from showrooms near you
          </Link>
        ))}
      </div>
      <div className={styles.box}>
        {/* {models.slice(0, itemsToShow).map((model, index) => ( */}
        <h3>Popular Cars On Road Price And Offers</h3>
        {models.map((model, index) => (
          <Link
            to={`/${model.slug}/on-road-price/offers`}
            key={`${model.id}/${index}`}
          >
            {model.name} on road price and offers
          </Link>
        ))}
      </div>
      <div className={styles.box}>
        {/* {models.slice(0, itemsToShow).map((model, index) => ( */}
        <h3>Book New Cars Online</h3>
        {models.map((model, index) => (
          <Link to={`/${model.slug}`} key={`${model.id}/${index}`}>
            {model.name}
          </Link>
        ))}
      </div>
      {/* {itemsToShow === 5 ? (
        <p className={styles.showmore} onClick={() => showmore(models)}>
          Show More
        </p>
      ) : (
        <p className={styles.showmore} onClick={showless}>
          Show Less
        </p>
      )} */}
    </div>
  );
};

export default Links;
