import React, { useCallback, useEffect, useRef } from "react";
import { changeNumberFormat } from "../../../utils";
import styles from "./multiRangeSlider.module.css";

const MultiRangeSlider = ({
  min,
  max,
  minVal,
  maxVal,
  setMinVal,
  setMaxVal,
  budgetHandler,
}) => {
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  return (
    <div className={styles.container}>
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          budgetHandler();
          event.target.value = value.toString();
        }}
        className={`${styles.thumb} ${styles.thumb__zindex_3} ${
          styles.thumb__zindex_5
        }: ${minVal > max - 100}`}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          budgetHandler();
          event.target.value = value.toString();
        }}
        className={`${styles.thumb} ${styles.thumb__zindex_4}`}
      />

      <div className={styles.slider}>
        <div className={styles.slider__track} />
        <div ref={range} className={styles.slider__range} />
        <div className={styles.slider__left_value}>
          {changeNumberFormat(minVal, 2)}
        </div>
        <div className={styles.slider__right_value}>
          {changeNumberFormat(maxVal, 2)}
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
