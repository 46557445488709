import React from "react";
import { Carousel } from "react-bootstrap";
import styles from "./Testimonials.module.css";

const Testimonials = () => {
  return (
    <Carousel fade>
      <Carousel.Item>
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.review}>
              <div className={styles.line}>&#10077;</div>
              <p>
                Booked Two Test Drives From Maruti Suzuki and Tata, Directly
                From Nearby Showrooms, Carbae Has all the showrooms as well.
              </p>
              <div>
                <h3>Vineeth</h3>
                <p className={styles.location}>North Paravoor</p>
              </div>
              <div className={styles.star}>&#9733; &#9733; &#9733; &#9733;</div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.review}>
              <div className={styles.line}>&#10077;</div>
              <p>
                A Virtual Showroom of all the cars, it's quite convinient to
                book test drives instead of through lot of websites and phone
                calls.I
              </p>
              <div>
                <h3>Shabeeb M</h3>
                <p className={styles.location}>Fort Kochi</p>
              </div>{" "}
              <div className={styles.star}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className={styles.background}>
          <div className={styles.content}>
            <div className={styles.review}>
              <div className={styles.line}>&#10077;</div>
              <p>
                Don't have to find number, call, arrange test-drives, all of
                that can be done through Carbae.
              </p>
              <div>
                <h3>John M</h3>
                <p className={styles.location}>Kakkanad </p>
              </div>{" "}
              <div className={styles.star}>&#9733; &#9733; &#9733; &#9733;</div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Testimonials;
