import { getApi, postApi, postApiClean, updateApi } from "./utils";
import Axios from "axios";
export const sendOTP = ({ email = "", mobile = "", firstName, lastName }) => {
  return postApi("customers/send_otp", {
    email: email,
    phone: mobile,
    first_name: firstName,
    last_name: lastName,
  });
};

export const getUser = (id) => {
  return getApi(`customers/${id}`);
};

export const updateUser = (id, data) => {
  return updateApi(`customers/${id}`, data);
};

export const verifyOTP = async ({ otp = "", phone }) => {
  const res = await postApiClean("customers/verify_otp", {
    otp: otp,
    phone: phone,
  });
  if (res.status === "error" || (res.error && res.error.length)) {
    throw new Error(res.message);
  } else {
    return res;
  }
};
export const getGeoCode = async (lat, lng) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_TOKEN;
  const url = `/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;

  try {
    const response = await Axios.get(url);
    if (response.data.status === "OK") {
      return response.data.results[0].formatted_address;
    }
    throw new Error("Geocoding failed");
  } catch (error) {
    console.error(error);
  }
};
