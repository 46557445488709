import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../login/loginSlice";
import { createOrder, verifyOrder } from "../../api/models";
import { resetActiveModel } from "../model/modelSlice";

export const slice = createSlice({
  name: "checkout",
  initialState: {
    order: {},
    payment: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    orderCreateSuccess: (state, action) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.order = action.payload;
    },
    orderCreateFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
      state.order = {};
    },
    orderReset: (state) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.order = {};
    },
    paymentCreateSuccess: (state, action) => {
      state.payment = action.payload;
    },
    paymentCreateFailed: (state, action) => {
      state.loading = false;
      state.error.haveError = false;
      state.payment = {};
    },
  },
});

export const selectOrderData = (state) => state.checkout.order || {};
export const selectPaymentData = (state) => state.checkout.payment || {};
export const selectCheckoutError = (state) => state.checkout.error || {};

const { orderCreateSuccess, orderCreateFailed, orderReset } = slice.actions;

export const createOrderAPI = (activeProduct) => async (dispatch) => {
  try {
    const data = await createOrder(activeProduct);
    dispatch(orderCreateSuccess(data));
  } catch (err) {
    dispatch(logout());
    dispatch(
      orderCreateFailed({
        message: err,
      })
    );
  }
};

export const verifyOrderAPI = (order, callback) => async (dispatch) => {
  try {
    const data = await verifyOrder(order);
    dispatch(resetOrderData());
    dispatch(resetActiveModel());
    console.log(data);
    callback(`/order-success?order_id=${order.razorpay_order_id}`);
  } catch (err) {
    console.error(
      "Payment verification failed. Don't worry, Our customer executive will contact you.",
      err
    );
  }
};

export const resetOrderData = () => async (dispatch) => {
  dispatch(orderReset());
};

export default slice.reducer;
