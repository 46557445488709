import { createSlice } from "@reduxjs/toolkit";
import { createLead, financeDeatils, financeLead } from "../../api/offer";

export const slice = createSlice({
  name: "offer",
  initialState: {
    data: {},
    finance: {},
    financeLead: {},
    loading: true,
    financeLoading: true,
    financeLeadLoading: true,
    financeError: {
      message: "",
      haveError: false,
    },
    financeLeadError: {
      message: "",
      haveError: false,
    },
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    offerLeadSuccess: (state, action) => {
      state.loading = false;
      state.error.haveError = false;
      state.data = action.payload;
    },
    offerLeadFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
    },
    financeSuccess: (state, action) => {
      state.financeLoading = false;
      state.financeError.haveError = false;
      state.finance = action.payload;
    },
    financeFailed: (state, action) => {
      state.financeLoading = false;
      state.financeError = {
        message: action.payload.message,
        haveError: true,
      };
    },
    financeLeadSuccess: (state, action) => {
      state.financeLeadLoading = false;
      state.financeLeadError.haveError = false;
      state.financeLead = action.payload;
    },
    financeLeadFailed: (state, action) => {
      state.financeLeadLoading = false;
      state.financeLeadError = {
        message: action.payload.message,
        haveError: true,
      };
    },
  },
});

export const selectLeadData = (state) => state.offer.data || {};
export const selectLeadError = (state) => state.offer.error || {};
export const selectLeadLoading = (state) => state.offer.loading || false;
export const selectFinanceData = (state) => state.offer.finance || {};
export const selectFinanceError = (state) => state.offer.financeError || {};
export const selectFinanceLoading = (state) =>
  state.offer.financeLoading || false;
export const selectFinanceLeadData = (state) => state.offer.financeLead || {};
export const selectFinanceLeadError = (state) =>
  state.offer.financeLeadError || {};
export const selectFinanceLeadLoading = (state) =>
  state.offer.financeLoading || false;
const {
  offerLeadSuccess,
  offerLeadFailed,
  financeFailed,
  financeSuccess,
  financeLeadFailed,
  financeLeadSuccess,
} = slice.actions;

export const createLeadApi = (inputData) => async (dispatch) => {
  try {
    const data = await createLead(inputData);
    dispatch(offerLeadSuccess(data));
  } catch (err) {
    dispatch(offerLeadFailed(err.toString()));
  }
};

export const FinanceApi = (inputData) => async (dispatch) => {
  try {
    const data = await financeDeatils(inputData);
    dispatch(financeSuccess(data));
  } catch (err) {
    dispatch(financeFailed(err.toString()));
  }
};

export const FinanceLeadApi = (inputData) => async (dispatch) => {
  try {
    const data = await financeLead(inputData);
    dispatch(financeLeadSuccess(data));
  } catch (err) {
    dispatch(financeLeadFailed(err.toString()));
  }
};

export default slice.reducer;
