import { createSlice } from "@reduxjs/toolkit";
import { getUser, updateUser } from "../../api/user";

export const slice = createSlice({
  name: "userProfile",
  initialState: {
    data: {},
    loading: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    userSuccess: (state, action) => {
      state.loading = false;
      state.error = {
        message: "",
        haveError: false,
      };
      state.userProfile = action.payload;
    },
    userFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
      state.userProfile = {};
    },
  },
});

const { userSuccess, userFailed } = slice.actions;

export const selectProfileUserData = (state) =>
  state.userProfile.userProfile || {};

export const getUserAPI = (id) => async (dispatch) => {
  try {
    const data = await getUser(id);
    dispatch(userSuccess(data));
  } catch (err) {
    dispatch(
      userFailed({
        message: err,
      })
    );
  }
};
export const updateUserAPI = (id, selectedData) => async (dispatch) => {
  try {
    const data = await updateUser(id, selectedData);
    dispatch(userSuccess(data));
  } catch (err) {
    dispatch(
      userFailed({
        message: err,
      })
    );
  }
};

export default slice.reducer;
