import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUserData } from "../login/loginSlice";
import {
  selectActive,
  setActiveModelTestDrive,
  createOrderAPI,
} from "../testdrive/testDriveSlice";
import { selectModelData } from "../model/modelSlice";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.css";
import { get } from "lodash";
import { ImageWithoutStyles } from "../../components/atoms/Image/Image";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import { getGeoCode } from "../../api/user";
import { updateUserAPI } from "../profile/profileSlice";
const TestDriveConfirmation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const product = useSelector(selectModelData);
  const userData = useSelector(selectUserData);
  const location = useSelector(selectLocationData);
  const active = useSelector(selectActive);
  const activeDealer =
    product?.dealer?.id === active.dealerId && product.dealer;
  const activeShowroom =
    product?.showroom?.id === active.showroomId && product.showroom;

  useEffect(() => {
    dispatch(
      setActiveModelTestDrive({
        type: "customerId",
        data: userData.userInfo.id,
      })
    );
    dispatch(
      setActiveModelTestDrive({
        type: "phone",
        data: userData.userInfo.phone,
      })
    );
  }, [dispatch, userData.userInfo]);

  const submitHandler = () => {
    dispatch(createOrderAPI(active, history.push));
  };
  useEffect(() => {
    if (
      location.lat &&
      location.lng &&
      get(userData, ["session", "access-token"])
    )
      getGeoCode(location.lat, location.lng).then((address) => {
        const payload = {
          lat: location.lat,
          lng: location.lng,
          current_location: address,
        };
        dispatch(updateUserAPI(userData?.userInfo?.id, payload));
      });
  }, [location, userData, dispatch]);

  return (
    <Container>
      {active.ModelId &&
        active.dealerId &&
        active.showroomId &&
        active.place &&
        active.date &&
        active.customerId &&
        active.phone && (
          <div>
            <div className={styles.model}>
              <Row>
                <Col md="5">
                  <div className={styles.image}>
                    <ImageWithoutStyles src={product.main_image} />
                  </div>
                </Col>
                <Col md="7" className={styles.content}>
                  <p className={styles.details}>
                    Model <span> : {product.name}</span>
                  </p>
                  <p className={styles.details}>
                    Dealer <span> : {activeDealer.name}</span>
                  </p>
                  <p className={styles.details}>
                    Showroom <span> : {activeShowroom.place}</span>
                  </p>
                </Col>
              </Row>
            </div>
            <div className={styles.confirmation}>
              <p className={styles.details}>
                Selected Date
                <span> : {get(active, ["date"])}</span>
              </p>
              <p className={styles.details}>
                Selected Location <span> : {get(active, ["place"])}</span>
              </p>

              <p className={styles.details}>
                Contact No <span> : {get(active, ["phone"])}</span>
              </p>
            </div>
            <Button
              className={styles.confirmbtn}
              disabled={
                !active.ModelId ||
                !active.dealerId ||
                !active.showroomId ||
                !active.place ||
                !active.date ||
                !active.customerId ||
                !active.phone
              }
              onClick={submitHandler}
            >
              Confirm TestDrive
            </Button>
          </div>
        )}
    </Container>
  );
};

export default TestDriveConfirmation;
