import React, { useState } from "react";
import { Accordion, Card, useAccordionToggle } from "react-bootstrap";
import styles from "./Faq.module.css";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="card-header" type="button" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}
const Faq = () => {
  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Accordion activeKey={activeKey}>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={0}
          handleClick={() => {
            if (activeKey === 0) {
              setActiveKey(null);
            } else {
              setActiveKey(0);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> What is Carbae.in ?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 0 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={0}>
          <Card.Body>
            <p>
              Carbae.in is an online platform that connects car buyer with the
              new car dealer. ie if you are looking to buy a new car and if ABC
              Motors is the dealer/showroom that is the authorised dealership in
              your location. Carbae connect you and ABC Motors online.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={1}
          handleClick={() => {
            if (activeKey === 1) {
              setActiveKey(null);
            } else {
              setActiveKey(1);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> What are the services provided by Carbae?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 1 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={1}>
          <Card.Body>
            <p>
              Through Carbae you can get all the services from a car showroom
              online.without having to make multiple call,visit multiple
              websites or visit multiple showrooms. ie you don't have to call
              ABC Motors, MNO Motors or XYZ Motors, or Visit all 3 showrooms.
              You can just login to carbae and interact with them that is
              <br />
              <br />
              You can get the most accurate price quotes and offers from your
              nearest car showroom ( ABC ,MNO or XYZ)
              <br />
              <br />
              You can schedule test drives directly from your nearest showrooms
              online. ( Yes!, you can book multiple test-drives, and yes Carbae
              is the only Multi-brand Test Drive Booking Platform)
              <br />
              <br />
              You can book cars directly from your nearest showrooms.( Let's
              say, a new car is launched and you have been waiting to buy the
              car, normally you have to find the showroom, then find the number
              and then contact the person, and then book right?, but with Carbae
              you can book directly from any showrooms and get priority
              delivery)
              <br />
              <br />
              In short Carbae Provides a virtual showroom of all the cars.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={2}
          handleClick={() => {
            if (activeKey === 2) {
              setActiveKey(null);
            } else {
              setActiveKey(2);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> How does carbae work? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 2 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={2}>
          <Card.Body>
            <p>
              It is simple, if you book a test drive or a car from ABC Motors,
              it directly goes to ABC Motors Carbae Dealer Dashboard. and then
              the sales executive will contact you to take you to your dream
              car.A combination of technology and Human Interaction created
              uniquely for you to enjoy a seamless car buying experience.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={3}
          handleClick={() => {
            if (activeKey === 3) {
              setActiveKey(null);
            } else {
              setActiveKey(3);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> How does car booking work? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 3 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={3}>
          <Card.Body>
            <p>
              You can chose a model, color and select a variant of that model,
              and you select the showroom nearest to you and you pay a mini
              booking amount, which will directly goes to the showroom you are
              selected, simply, it is same as going to nearest showroom and
              booking, but now you can do it online without even moving from
              your seat.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={4}
          handleClick={() => {
            if (activeKey === 4) {
              setActiveKey(null);
            } else {
              setActiveKey(4);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> What are the payment modes available? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 4 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={4}>
          <Card.Body>
            <p>
              Carbae has partnered with one of best payment gateways to give you
              as much as options as possible, you can pay with debit card,
              credit card, UPI and you can also pay with popular wallets such as
              phonepe, google pay and paytm as well.{" "}
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={5}
          handleClick={() => {
            if (activeKey === 5) {
              setActiveKey(null);
            } else {
              setActiveKey(5);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> What is the refund policy? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 5 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={5}>
          <Card.Body>
            <p>
              It depends on the dealership/showroom you book your cars from,
              Carbae act as an intermediatory between you and the showoom by
              providing the platform.{" "}
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={6}
          handleClick={() => {
            if (activeKey === 6) {
              setActiveKey(null);
            } else {
              setActiveKey(6);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Why should I use Carbae not any other platform? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 6 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={6}>
          <Card.Body>
            <p>
              Buying a new car is a major milestone for any individual, it opens
              up roads, it brings your close ones even more closer and it is
              also one of the biggest financial investments one would
              make.Carbae envision to make new car buying as easy, hassle-free,
              transparent and complete as possible. without going through
              multiple showrooms physically or battling over phone with calls
              from all over the country. Carbae will help you select the right
              car by bringing you the offers and services in to your fingertips
              so that you can chose the one that suits you the most and we will
              make sure to connect you with the right place and right place only
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={7}
          handleClick={() => {
            if (activeKey === 7) {
              setActiveKey(null);
            } else {
              setActiveKey(7);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              How does Carbae help me find the accurate on-road price of cars
              near me?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 7 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={7}>
          <Card.Body>
            <p>
              At Carbae, we revolutionize the car buying experience by enabling
              you to find accurate on-road prices of cars near you with just a
              few clicks. Our platform aggregates real-time data from authorized
              dealerships, ensuring that you have access to the most up-to-date
              pricing information and exclusive offers. No more showroom visits
              or endless phone calls - Carbae makes it effortless to compare
              prices, grab the latest deals, and make informed decisions.
              Experience the convenience of finding accurate on-road prices at
              your fingertips with Carbae.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={8}
          handleClick={() => {
            if (activeKey === 8) {
              setActiveKey(null);
            } else {
              setActiveKey(8);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              What are the latest offers and discounts available on my favorite
              car models?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 8 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={8}>
          <Card.Body>
            <div>
              <p>
                At Carbae, we keep you updated with the latest offers and
                discounts on your favorite car models. Here's how you can find
                out about them:
              </p>
              <ol>
                <li>Visit the Carbae website</li>
                <li>
                  Navigate to the "Offers" section or look for the "Latest
                  Offers" tab.
                </li>
                <li>
                  Browse through the available offers, which are regularly
                  updated.
                </li>
                <li>
                  Filter the offers based on your preferred car brand, model, or
                  location to find the ones that match your interests.
                </li>
                <li>
                  Click on the offer to view more details, including any
                  specific terms and conditions.
                </li>
                <li>
                  If you find an offer that suits your requirements, you can
                  contact the respective dealership directly through Carbae for
                  further assistance or to avail of the offer.
                </li>
              </ol>
              <p>
                By using Carbae, you can conveniently explore the latest offers
                and discounts on your favorite car models in one place. Don't
                miss out on the opportunity to grab exciting deals and make your
                car buying experience even more rewarding. Start discovering the
                latest offers on Carbae today!
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Faq;

export const TestDriveFaq = () => {
  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Accordion activeKey={activeKey}>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={0}
          handleClick={() => {
            if (activeKey === 0) {
              setActiveKey(null);
            } else {
              setActiveKey(0);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> How can I schedule a test drive? </h3>
            <span className={styles.plusMinus}>
              {activeKey === 0 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={0}>
          <Card.Body>
            <p>
              Scheduling a test drive is quick and easy. Simply click on the
              "Schedule Test Drive" button on the car's page, fill in your
              preferred date, time, and location, and submit the form. Our team
              will get in touch with you to confirm the details.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={1}
          handleClick={() => {
            if (activeKey === 1) {
              setActiveKey(null);
            } else {
              setActiveKey(1);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Is there any cost associated with booking a test drive?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 1 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={1}>
          <Card.Body>
            <p>
              No, booking a test drive through Carbae is completely free of
              charge. It's a service we provide to help you make an informed
              decision before purchasing a car.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={2}
          handleClick={() => {
            if (activeKey === 2) {
              setActiveKey(null);
            } else {
              setActiveKey(2);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Can I choose the location for the test drive?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 2 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={2}>
          <Card.Body>
            <p>
              Absolutely! You can select the location for your test drive based
              on your convenience. Choose from our available authorized
              showrooms or request a test drive at your preferred location
              (subject to feasibility).
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={3}
          handleClick={() => {
            if (activeKey === 3) {
              setActiveKey(null);
            } else {
              setActiveKey(3);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> How long does a test drive usually last?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 3 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={3}>
          <Card.Body>
            <p>
              The duration of a test drive can vary depending on the car model
              and the policies of the authorized showroom. On average, a test
              drive usually lasts around 15 to 30 minutes, allowing you to get a
              feel for the car's performance and features.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={4}
          handleClick={() => {
            if (activeKey === 4) {
              setActiveKey(null);
            } else {
              setActiveKey(4);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Can I bring someone with me for the test drive?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 4 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={4}>
          <Card.Body>
            <p>
              Yes, you are welcome to bring someone along for the test drive. It
              can be helpful to have a second opinion or input during the
              experience.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={5}
          handleClick={() => {
            if (activeKey === 5) {
              setActiveKey(null);
            } else {
              setActiveKey(5);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> What documents do I need to bring for the test drive?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 5 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={5}>
          <Card.Body>
            <p>
              Typically, you will need to bring your valid driver's license to
              participate in a test drive. It's advisable to carry any
              additional identification or documentation as requested by the
              authorized showroom.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={6}
          handleClick={() => {
            if (activeKey === 6) {
              setActiveKey(null);
            } else {
              setActiveKey(6);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Can I reschedule or cancel my test drive?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 6 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={6}>
          <Card.Body>
            <p>
              If you need to reschedule or cancel your test drive, please
              contact our team or the authorized showroom as soon as possible.
              They will assist you in making the necessary changes.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={7}
          handleClick={() => {
            if (activeKey === 7) {
              setActiveKey(null);
            } else {
              setActiveKey(7);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Are there any age restrictions for test driving a car?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 7 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={7}>
          <Card.Body>
            <p>
              Yes, you must meet the legal driving age requirement and hold a
              valid driver's license to participate in a test drive.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={8}
          handleClick={() => {
            if (activeKey === 8) {
              setActiveKey(null);
            } else {
              setActiveKey(8);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3> Can I test drive multiple car models?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 8 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={8}>
          <Card.Body>
            <p>
              Absolutely! You can request test drives for multiple car models to
              compare and make an informed decision. Simply select the desired
              models and schedule the test drives accordingly.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export const HomeFaq = () => {
  const [activeKey, setActiveKey] = useState(-1);

  return (
    <Accordion activeKey={activeKey}>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={0}
          handleClick={() => {
            if (activeKey === 0) {
              setActiveKey(null);
            } else {
              setActiveKey(0);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>1 . What is carbae.in?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 0 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={0}>
          <Card.Body>
            <p>
              Carbae.in is an online platform that revolutionizes the new car
              buying experience. We connect car buyers with the nearest
              authorized showrooms of top car brands, offering a wide range of
              new cars for sale. Our platform provides essential information and
              services to simplify and streamline the car buying process and
              ensure a seamless experience for our customers.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={1}
          handleClick={() => {
            if (activeKey === 1) {
              setActiveKey(null);
            } else {
              setActiveKey(1);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              2 . Can I book a test drive through Carbae? How does it work?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 1 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={1}>
          <Card.Body>
            <div>
              <p>
                Certainly! With Carbae, booking a test drive for your favorite
                car is quick and hassle-free. Simply follow these steps:
              </p>
              <ol>
                <li>Visit the Carbae website or mobile app.</li>
                <li>
                  Browse through our wide range of available car models and
                  select the one you're interested in.
                </li>
                <li>
                  On the car's details page, you'll find a "Book Test Drive"
                  button. Click on it.
                </li>
                <li>
                  Fill in your contact details and preferred date and time for
                  the test drive.
                </li>
                <li>
                  Submit your request, and Carbae will instantly notify the
                  nearest dealerships offering the selected car model
                </li>
              </ol>
              <p>
                Sit back and relax! The dealership will contact you to confirm
                the test drive appointment and provide further instructions.
              </p>
              <p>
                By using Carbae, you can conveniently schedule a test drive
                without the need for multiple phone calls or showroom visits. It
                saves you time and ensures a seamless experience. Start
                exploring and book your test drive with Carbae today!
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={2}
          handleClick={() => {
            if (activeKey === 2) {
              setActiveKey(null);
            } else {
              setActiveKey(2);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>3 . What services does Carbae offer?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 2 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={2}>
          <Card.Body>
            <div>
              <p>
                Carbae offers a range of services to assist car buyers in their
                purchasing journey. Our services include:
              </p>
              <ol>
                <li>
                  Extensive Car Selection: We provide access to a wide range of
                  top car brands, allowing you to explore different models,
                  variants, and specifications.
                </li>
                <li>
                  On-Road Price and Offers: Carbae displays the on-road prices
                  of cars, inclusive of taxes, insurance, and other charges. We
                  also highlight the latest offers and discounts available,
                  ensuring you get the best deal.
                </li>
                <li>
                  Test Drive Booking: With Carbae, you can easily schedule a
                  test drive of your preferred car model. Choose the date, time,
                  and location that suits you best, and experience the car
                  firsthand.
                </li>
                <li>
                  Online Booking: Carbae enables you to book your desired car
                  online, eliminating the need for lengthy paperwork and
                  multiple visits to the showroom. It's a seamless and efficient
                  way to secure your dream car.
                </li>
                <li>
                  Hassle-Free Buying Experience: We aim to make the car buying
                  process hassle-free and transparent. Our team of experts is
                  available to assist you at every step, providing guidance,
                  answering queries, and ensuring a smooth purchasing
                  experience.
                </li>
              </ol>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={3}
          handleClick={() => {
            if (activeKey === 3) {
              setActiveKey(null);
            } else {
              setActiveKey(3);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              4 . How can Carbae save me time and effort during the car buying
              process?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 3 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={3}>
          <Card.Body>
            <div>
              <p>
                Carbae is designed to save you time and effort during the car
                buying process. Here's how:
              </p>
              <ul>
                <li>
                  Comprehensive Information: Carbae provides you with detailed
                  information about various car models, including
                  specifications, features, and pricing. This helps you make
                  informed decisions without the need for extensive research or
                  multiple visits to different showrooms.
                </li>
                <li>
                  On-Road Price Comparison: With Carbae, you can easily compare
                  on-road prices of cars from multiple dealerships near you.
                  This saves you the hassle of individually contacting each
                  showroom or visiting them physically to gather price
                  information.
                </li>
                <li>
                  Test Drive Booking: Carbae offers the convenience of booking
                  test drives directly through the platform. Simply select your
                  preferred car model, choose a convenient date and time, and
                  book your test drive. This eliminates the need for multiple
                  phone calls or showroom visits to schedule test drives.
                </li>
                <li>
                  Exclusive Offers: Carbae keeps you updated with the latest
                  offers and discounts on your favorite car models. By accessing
                  these offers through Carbae, you can save time scouring
                  multiple sources for deals and promotions.
                </li>
                <li>
                  Streamlined Communication: Carbae acts as a centralized
                  platform for communication between you and the car
                  dealerships. You can connect with dealerships, inquire about
                  specific cars, negotiate prices, and finalize your purchase,
                  all within the Carbae platform.
                </li>
              </ul>
              <p>
                By leveraging the features and services provided by Carbae, you
                can streamline your car buying journey, saving valuable time and
                effort. Focus on finding the right car for your needs while
                Carbae takes care of the logistics and simplifies the process
                for you.
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={4}
          handleClick={() => {
            if (activeKey === 4) {
              setActiveKey(null);
            } else {
              setActiveKey(4);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              5 . Is Carbae affiliated with authorized dealerships? Are the
              prices and offers on Carbae genuine?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 4 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={4}>
          <Card.Body>
            <p>
              Yes, Carbae is affiliated with authorized dealerships of various
              car brands. We partner with reputable and trusted dealerships to
              ensure that the prices and offers displayed on our platform are
              genuine and accurate.
            </p>
            <p>
              We understand the importance of providing reliable information to
              our users, which is why we work closely with authorized
              dealerships who adhere to industry standards and pricing
              guidelines. The prices you see on Carbae reflect the on-road
              prices provided by these dealerships, including the ex-showroom
              price, applicable taxes, registration fees, and other charges.
            </p>
            <p>
              The offers and discounts featured on Carbae are also genuine and
              provided directly by the dealerships. We strive to keep our
              platform updated with the latest promotions and special deals
              offered by the dealerships, so you can take advantage of the best
              offers available
            </p>
            <p>
              At Carbae, our commitment is to provide you with transparent and
              accurate information, ensuring a trustworthy and reliable car
              buying experience. You can have confidence in the prices and
              offers displayed on our platform, as they come directly from
              authorized dealerships.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={5}
          handleClick={() => {
            if (activeKey === 5) {
              setActiveKey(null);
            } else {
              setActiveKey(5);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>6 . How can I compare different car models on Carbae?</h3>

            <span className={styles.plusMinus}>
              {activeKey === 5 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={5}>
          <Card.Body>
            <div>
              <p>
                Comparing different car models on Carbae is easy and convenient.
                Here's how you can do it:
              </p>
              <ol>
                <li>
                  Visit the Carbae website or app and navigate to the search
                  section.
                </li>
                <li>
                  Enter the specific car models you want to compare in the
                  search bar.
                </li>
                <li>
                  You will be presented with a list of car models matching your
                  search criteria.
                </li>
                <li>
                  Select the car models you wish to compare by clicking on them.
                </li>
                <li>
                  On the car model pages, you will find detailed information
                  about each model, including specifications, features, on-road
                  prices, and more.
                </li>
                <li>
                  To compare the selected models, look for the "Compare" button
                  or feature on the page. Click on it.
                </li>
                <li>
                  A side-by-side comparison view will be displayed, allowing you
                  to compare the key features, specifications, prices, and other
                  relevant information of the chosen car models.
                </li>
                <li>
                  Analyze the compared features and make an informed decision
                  based on your preferences and requirements
                </li>
              </ol>
              <p>
                Carbae enables you to efficiently compare multiple car models,
                helping you make a well-informed choice that best suits your
                needs.
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={6}
          handleClick={() => {
            if (activeKey === 6) {
              setActiveKey(null);
            } else {
              setActiveKey(6);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>
              7 . Can I customize my search criteria on Carbae, such as budget,
              fuel type, or specific features?
            </h3>
            <span className={styles.plusMinus}>
              {activeKey === 6 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={6}>
          <Card.Body>
            <div>
              <p>
                Yes, on Carbae, you have the flexibility to customize your
                search criteria to find the perfect car that meets your
                preferences. Our platform allows you to refine your search based
                on various parameters, including:
              </p>
              <ul>
                <li>
                  Budget: You can set your desired budget range to view cars
                  within your price range.
                </li>
                <li>
                  Fuel Type: If you have a preference for a specific fuel type,
                  such as petrol, diesel, or electric, you can select your
                  preferred fuel type to narrow down your search results
                  accordingly.
                </li>
                <li>
                  Specific Features: Carbae provides the option to specify
                  certain features or characteristics you are looking for in a
                  car, such as automatic transmission, seating capacity, safety
                  features, infotainment system, and more
                </li>
              </ul>
              <p>
                By customizing your search criteria, you can quickly and
                efficiently find cars that match your requirements and
                preferences. Carbae's user-friendly interface and advanced
                search filters make it convenient to refine your search and
                explore a wide range of options that best suit your needs.
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card className={styles.faqcard}>
        <CustomToggle
          as={Card.Header}
          eventKey={6}
          handleClick={() => {
            if (activeKey === 6) {
              setActiveKey(null);
            } else {
              setActiveKey(6);
            }
          }}
        >
          <div className={styles.qstn}>
            <h3>8 . How does carbae work?</h3>
            <span className={styles.plusMinus}>
              {activeKey === 6 ? "-" : "+"}
            </span>
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey={6}>
          <Card.Body>
            <div>
              <p>
                Carbae works by seamlessly connecting car buyers with authorized
                dealerships and streamlining the car buying process. Here's how
                it works:
              </p>
              <ul>
                <li>
                  Browse: Explore the wide selection of car models and brands
                  available on carbae.in.
                </li>
                <li>
                  Research: Get detailed information about each car, including
                  on-road prices, features, specifications, and customer
                  reviews.
                </li>
                <li>
                  Book: Select your preferred car, book a test drive online, and
                  schedule it according to your convenience.
                </li>
                <li>
                  Purchase: Finalize your car purchase with the help of our
                  authorized dealerships and avail of the best offers and
                  financing options.
                </li>
                <li>
                  Enjoy: Experience a hassle-free car buying journey with
                  Carbae, ensuring a seamless and convenient process from start
                  to finish.
                </li>
              </ul>
              <p>
                By customizing your search criteria, you can quickly and
                efficiently find cars that match your requirements and
                preferences. Carbae's user-friendly interface and advanced
                search filters make it convenient to refine your search and
                explore a wide range of options that best suit your needs.
              </p>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
