import { createSlice } from "@reduxjs/toolkit";
import { searchModels } from "../../api/models";

export const slice = createSlice({
  name: "brands",
  initialState: {
    results: [],
    loadingResults: false,
    error: {
      message: "",
    },
  },
  reducers: {
    setLoading: (state) => {
      state.loadingResults = true;
    },
    getResultssSuccess: (state, action) => {
      state.results = action.payload;
      state.loadingResults = false;
      state.error = {};
    },
    getResultsFailed: (state) => {
      state.results = [];
      state.loadingResults = false;
      state.error.message = "Something went wrong";
    },
  },
});

export const selectResults = (state) => state.brands.results || [];
export const selectLoading = (state) => state.brands.loadingResults;

export const { setLoading, getResultssSuccess, getResultsFailed } =
  slice.actions;
export const fetchBrand = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const results = await searchModels(params);
    dispatch(getResultssSuccess(results));
  } catch (err) {
    dispatch(getResultsFailed(err.toString()));
  }
};

export default slice.reducer;
