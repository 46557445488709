export const PriceRanges = [
  { name: { start: 300000, end: 800000 } },
  { name: { start: 800000, end: 1500000 } },
  { name: { start: 1500000, end: 2000000 } },
  { name: { start: 2000000, end: 2500000 } },
  { name: { start: 2500000, end: 3000000 } },
  { name: { start: 3000000, end: 4000000 } },
  { name: { start: 4000000, end: 13000000 } },
];
export const FuelType = {
  both_diesel_and_petrol: "Diesel & Petrol",
  diesel: "Diesel",
  petrol: "Petrol",
  cng: "CNG",
  electric: "Electric",
  cng_and_pertrol: "CNG & Petrol",
  cng_and_petrol: "CNG & Petrol",
};
export const TransmissionType = {
  both: "Both",
  manual: "Manual",
  automatic: "Automatic",
};
export const BodyType = {
  suv: "SUV",
  hatchback: "HatchBack",
  sedan: "Sedan",
  minivan: "Minivan",
  muv: "MUV",
  cuv: "CUV",
  pickup: "PickUp",
};
export const Brand = {
  26: "Mahindra",
  30: "Maruti Suzuki Arena",
  31: "Honda",
  33: "Tata Motors",
  35: "Maruti Suzuki Nexa",
  36: "Skoda",
  37: "Nissan",
  38: "Volkswagen",
  39: "Hyundai",
  40: "Jeep",
  41: "Toyota",
};
