import { createSlice } from "@reduxjs/toolkit";
import { sendOTP, verifyOTP } from "../../api/user";
import get from "lodash/get";

export const slice = createSlice({
  name: "user",
  initialState: {
    data: {},
    waitingForOTP: false,
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    OTPSendSuccess: (state) => {
      state.waitingForOTP = true;
      state.error.haveError = false;
    },
    OTPSendFailed: (state, action) => {
      state.data = {};
      state.waitingForOTP = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
    },
    OTPVerifySuccess: (state, action) => {
      state.data = action.payload;
      state.waitingForOTP = false;
      state.error.haveError = false;
    },
    OTPVerifyFailed: (state, action) => {
      state.data = {};
      state.waitingForOTP = true;
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    logout: (state) => {
      state.data = {};
      state.waitingForOTP = false;
      state.error = {
        message: "",
        haveError: false,
      };
    },
  },
});

export const selectUserData = (state) => state.user.data || {};
export const selectLoginErr = (state) => state.user.error || {};
export const selectWaitingForOTP = (state) => state.user.waitingForOTP || false;

export const {
  loginApiLoading,
  OTPSendSuccess,
  OTPSendFailed,
  OTPVerifySuccess,
  OTPVerifyFailed,
  logout,
} = slice.actions;
export const sentOTPToUser =
  ({ email = "", mobile = "", firstName = "", lastName = "" }, setSubmitting) =>
  async (dispatch) => {
    try {
      await sendOTP({ email, mobile, firstName, lastName });
      dispatch(OTPSendSuccess());
      setSubmitting(false);
    } catch (err) {
      dispatch(OTPSendSuccess(err.toString()));
      setSubmitting(false);
    }
  };

export const verifyOTPFromUser =
  ({ otp, phone }, setSubmitting, navigate, navigateTo = "/") =>
  async (dispatch) => {
    try {
      const loginSuccessDetails = await verifyOTP({ otp, phone });
      const userData = {
        userInfo: get(loginSuccessDetails, ["data", "data"]),
        session: get(loginSuccessDetails, ["data", "token"]),
      };
      if (!setSubmitting) return;
      dispatch(OTPVerifySuccess(userData));
      setSubmitting(false);
      navigate(navigateTo);
    } catch (err) {
      if (!setSubmitting) return;
      dispatch(OTPVerifyFailed(err.toString()));
      setSubmitting(false);
    }
  };

export const verifyOTPFromUserPopUp =
  ({ otp, phone }, setSubmitting) =>
  async (dispatch) => {
    try {
      const loginSuccessDetails = await verifyOTP({ otp, phone });
      const userData = {
        userInfo: get(loginSuccessDetails, ["data", "data"]),
        session: get(loginSuccessDetails, ["data", "token"]),
      };
      if (!setSubmitting) return;
      dispatch(OTPVerifySuccess(userData));
      setSubmitting(false);
    } catch (err) {
      if (!setSubmitting) return;
      dispatch(OTPVerifyFailed(err.toString()));
      setSubmitting(false);
    }
  };

export default slice.reducer;
