import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, useHistory, useParams } from "react-router-dom";

import get from "lodash/get";
import Drawer from "react-bottom-drawer";
import { ErrorBoundary } from "react-error-boundary";

import styles from "./Model.module.css";
import offerstyles from "../offers/Offer.module.css";
import {
  selectModelData,
  loadModelFromAPI,
  selectActiveModel,
  resetActiveModel,
  setActiveModel,
} from "./modelSlice";
import Colors from "../../components/molecules/colors/Colors";
import VariantSelector from "../../components/molecules/variantSelector/VariantSelector";
import Image from "../../components/atoms/Image/Image";
import { useQuery } from "../../api/utils";
import { changeNumberFormat } from "../../utils";
import { logout, selectUserData } from "../login/loginSlice";

import { filterArray } from "../home/Utils";
import php from "./SVG/php.svg";
import seat from "./SVG/seat.svg";
import engine from "./SVG/engine.svg";
import fuel from "./SVG/fuel.svg";
import type from "./SVG/type.svg";
import offer from "./SVG/offer.svg";
import color from "./SVG/color.png";
import { FuelType, BodyType, TransmissionType } from "../home/data";
import { Helmet } from "react-helmet";
import Features from "../features/Features";
import Loading from "../../components/atoms/loading/Loading";
import Faq from "../../components/molecules/faq/Faq";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import { createLeadApi } from "../offers/offerSclice";
import close from "./SVG/close.svg";
import LoginPopUp from "../login/LoginPopUp";

import marked from "marked";
import DOMPurify from "dompurify";

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productId } = useParams();
  const location = useSelector(selectLocationData);
  const [apiCall, setApiCall] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);
  const [buyButton, setBuyButton] = useState(false);

  useEffect(() => {
    dispatch(
      loadModelFromAPI(
        productId,
        location?.lat &&
          location?.lng && {
            lat: location?.lat,
            lng: location?.lng,
          },
        history
      )
    );
  }, [dispatch, productId, location.lng, location.lat, history]);

  const product = useSelector(selectModelData);
  // ************************
  const inSelectVariant = useQuery("selectVariant");

  const [showVariantSelector, setShowVariantSelector] = useState(
    !!inSelectVariant
  );

  const variantEl = React.createRef();
  const activeProductDetails = useSelector(selectActiveModel);

  useEffect(() => {
    if (product.id !== activeProductDetails.productId) {
      dispatch(resetActiveModel(product.id));
    }
  }, [activeProductDetails.productId, dispatch, product.id]);

  useEffect(() => {
    if (
      product.dealer &&
      product?.dealer?.id !== activeProductDetails.dealerId
    ) {
      dispatch(
        setActiveModel({
          type: "dealerId",
          data: product.dealer.id,
        })
      );
    }
  }, [activeProductDetails.dealerId, dispatch, product.dealer]);

  useEffect(() => {
    if (
      product.showroom &&
      product?.showroom?.id !== activeProductDetails.showroomId
    ) {
      dispatch(
        setActiveModel({
          type: "showroomId",
          data: product.showroom.id,
        })
      );
    }
  }, [activeProductDetails.showroomId, dispatch, product.showroom]);

  const showVariants = useCallback(() => {
    setShowVariantSelector(true);
    history.push(`/${product.slug}?selectVariant=true`);
  }, [history, product]);

  const bookNowHandler = () => {
    if (!buyButton) {
      setBuyButton(true);
    }

    if (activeProductDetails.colorId) {
      showVariants();
    }
  };

  useEffect(() => {
    if (buyButton && activeProductDetails.colorId) {
      showVariants();
    }
  }, [buyButton, activeProductDetails.colorId, showVariants]);

  const userData = useSelector(selectUserData);

  const callBackHandler = useCallback(() => {
    const payload = {
      dealer_id: product.dealer.id,
      showroom_id: product.showroom.id,
      model_id: product.id,
      // variant_id: 1,
    };
    dispatch(createLeadApi(payload));
    setApiCall(true);
    setApiCallSuccess(true);
  }, [dispatch, product]);

  const LoginHandler = () => {
    setLoginForm(true);
  };

  useEffect(() => {
    if (loginForm && userData?.session && userData.session["access-token"]) {
      setLoginForm(false);
      callBackHandler();
    }
  }, [userData, loginForm, callBackHandler]);

  const checkout = () => {
    setShowVariantSelector(false);

    if (!get(activeProductDetails, ["colorId"])) {
      alert("You need to select a color of the vehicle first");
      history.push(`/${product.slug}`);
      return;
    }

    if (!get(activeProductDetails, ["variantId"])) {
      alert("Please select a variant");
      setShowVariantSelector(true);
      history.push(`/${product.slug}?selectVariant=true`);
      return;
    }

    if (
      !get(activeProductDetails, ["dealerId"]) ||
      !get(activeProductDetails, ["showroomId"])
    ) {
      alert("Dealer and Showroom not found");
      history.push(`/${product.slug}`);
      return;
    }

    if (get(userData, ["session", "access-token"])) {
      history.push(`/checkout`);
    } else {
      history.push(`/login?redirectTo=checkout`);
    }
  };
  // ***********************************************

  const [Fuel, setFuel] = useState("");
  const [Type, setType] = useState("");

  const [filteredVariants, setFilteredVariants] = useState("");
  const [transmission_type, setTransmission_type] = useState("");
  const [fuel_type, setFuel_type] = useState("");

  const TypeHandler = (datasource, SetDataSource, type) => {
    if (datasource.indexOf(type) !== -1) {
      SetDataSource(datasource.filter((value) => value !== type));
    } else {
      SetDataSource((brandId) => [...brandId, type]);
    }
  };

  useEffect(() => {
    if (product.variants) {
      setFuel([
        ...new Set(product.variants.map((model) => model["fuel_type"])),
      ]);
      setType([
        ...new Set(product.variants.map((model) => model["transmission_type"])),
      ]);
      const filteredVariants = filterArray(product.variants, {
        transmission_type,
        fuel_type,
      });
      setFilteredVariants(filteredVariants);
    }
  }, [product, fuel_type, transmission_type]);
  // ***********************************************
  const name = product.id && product.name;

  return (
    <>
      {!product.id ? (
        <Loading />
      ) : (
        <Container>
          <Helmet>
            <title>
              {product.name}-Book Online-On Road Price-Test Drive-Great Offers
            </title>
            <meta
              name="description"
              content={`${product.name} on-road price, latest offers, and deals, images, colours variants on top car brands at Carbae.in. Book your ${product.name} online, schedule a test drive, and experience a hassle-free car buying`}
            />
            <meta
              name="keywords"
              content={`${name},Book Test Drive ${name},Test Drive ${name},Book ${name} Online ,${name} On Road Price,${name} Offers ,Book Test Drive, Test Drive, On Road Price, Online Car Booking, Carbae, Popular Car Brands, Car Dealer, Online Car Dealers, Best Car Dealers, Top Trending Cars, Cars On Road Price, New Released Cars`}
            />
            <script type="application/ld+json">
              {JSON.stringify({
                // "@context": "https://schema.org",
                // "@type": "Product",
                // name: product.name,
                // image: `${SERVER_HOST}${product.main_image}`,
                // description: product.description,

                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: [
                  {
                    "@type": "Question",
                    name: `What is the on road price of the ${product.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `The on road price of ${product.name} starts at Rs ${product.price_starts}  to  Rs ${product.price_ends} `,
                    },
                  },
                  {
                    "@type": "Question",
                    name: `What are the variants of ${product.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `There are ${
                        product?.variants?.length
                      } variants for ${
                        product.name
                      } and they are ${product?.variants?.map((n) => n.name)} `,
                    },
                  },
                  {
                    "@type": "Question",
                    name: `What are the colors options available for  ${product.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `${product.name} is available in ${
                        product?.colors?.length
                      } colors and they are ${product?.colors?.map(
                        (n) => n.name
                      )}`,
                    },
                  },
                ],
              })}
            </script>
          </Helmet>
          <Row className={styles.jumbo}>
            {apiCallSuccess && (
              <div
                className={offerstyles.modal}
                onClick={() => setApiCallSuccess(false)}
              >
                <span
                  onClick={() => setApiCallSuccess(false)}
                  className={offerstyles.btn}
                >
                  &times;
                </span>
                <div className={offerstyles.content}>
                  <div>
                    <p>
                      Thank You for contacting {product.dealer.name} -{" "}
                      {product.showroom.place}.
                    </p>
                    <p>We will contact you soon to assist you further.</p>
                  </div>
                  <div
                    onClick={() => setApiCallSuccess(false)}
                    className={offerstyles.close}
                  >
                    Okay
                  </div>
                </div>
              </div>
            )}
            {loginForm && (
              <div className={offerstyles.loginScreen}>
                <div className={offerstyles.shade}>
                  <div className={offerstyles.closeContainer}>
                    <div className={styles.loginHeader}>
                      <h3 className={styles.loginHeading}>
                        Your Special Offer Awaits! Log in to proceed.
                      </h3>
                      <img
                        src={close}
                        alt="close"
                        loading="lazy"
                        className={offerstyles.clearBtn}
                        onClick={() => {
                          setLoginForm(false);
                          dispatch(logout());
                        }}
                      />
                    </div>
                  </div>
                  <LoginPopUp />
                </div>
              </div>
            )}
            <Col md="4">
              <div className={`${styles.name} ${styles.mobileHide}`}>
                <h1 className={styles.heading}>{product.name}</h1>
              </div>
            </Col>
            <Col md="8">
              <ActiveImage product={product} className={styles.img} />
              {product?.colors?.length > 0 && (
                <div className={styles.colorpicker}>
                  <p
                    className={`${styles.selection} ${
                      buyButton && styles.selelctColor
                    }`}
                  >
                    {buyButton ? "Select Color" : "Colors"}
                  </p>
                  <img src={color} alt="color" loading="lazy" />
                </div>
              )}
              <Colors colors={product.colors} />
            </Col>
          </Row>

          <div className={styles.info}>
            <div>
              <div className={`${styles.name} ${styles.desktopHide}`}>
                <h1 className={styles.heading}>{product.name}</h1>
              </div>
              <h2 className={styles.price}>
                <span></span>
                {changeNumberFormat(product.price_starts)} to{" "}
                {changeNumberFormat(product.price_ends)}
              </h2>
              <div className={styles.filterBox}>
                <Link
                  className={`${styles.testdrive} ${styles.buyingBTN}`}
                  to={`/${product.slug}/on-road-price/offers`}
                >
                  On Road Price
                </Link>
                {product.colors && product.dealer && product.showroom && (
                  <div
                    type="primary"
                    onClick={bookNowHandler}
                    className={`${styles.testdrive} ${styles.mobileView}`}
                  >
                    Book Online
                  </div>
                )}

                <Link
                  className={`${styles.testdrive} ${styles.mobileView}`}
                  to={`/${product.slug}/testdrive`}
                >
                  Book Test Drive
                </Link>

                <div className={styles.buttonAlignment}>
                  {product.colors && product.dealer && product.showroom && (
                    <div
                      type="primary"
                      onClick={bookNowHandler}
                      className={styles.testdrive}
                    >
                      Book Online
                    </div>
                  )}
                  <Link
                    className={styles.testdrive}
                    to={`/${product.slug}/testdrive`}
                  >
                    Book Test Drive
                  </Link>
                </div>

                {/* <Link
                  className={styles.testdrive}
                  to={`/${product.slug}/exchange`}
                >
                  Exchange Car
                </Link> */}
              </div>
            </div>

            <div>
              {(!location.lat || !location.lng) && (
                <p className={styles.loc}>
                  Please Enable Your Location And Refresh The Page To Get On
                  Road Price And Offers From Showroom Near You
                </p>
              )}
            </div>

            {location.lat && location.lng && (
              <>
                {!!product?.offers?.length && (
                  <div className={styles.offers}>
                    <h2 className={offerstyles.offer_heading}>
                      Offers for you{" "}
                    </h2>
                    {product?.total_offers_upto > 0 && (
                      <>
                        <div className={offerstyles.offerTitle}>
                          <img src={offer} alt="close" loading="lazy" />
                          <p className={`${offerstyles.offer_text}  `}>
                            Total Offers
                          </p>
                        </div>
                        <div className={offerstyles.paddingContainer}>
                          <p className={offerstyles.offerPrice}>
                            {changeNumberFormat(product.total_offers_upto)}
                          </p>
                          <p className={offerstyles.offerText}>
                            Get upto a total of{" "}
                            {changeNumberFormat(product.total_offers_upto)}{" "}
                            discount on {product.name}.
                          </p>
                          <div className={offerstyles.offerButtons}>
                            {userData?.session &&
                            userData.session["access-token"] ? (
                              <Button
                                disabled={apiCall}
                                className={offerstyles.callbackButton}
                                onClick={callBackHandler}
                              >
                                Get Offer
                              </Button>
                            ) : (
                              <Button
                                disabled={apiCall}
                                className={offerstyles.callbackButton}
                                onClick={LoginHandler}
                              >
                                Get Offer
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            <div className={styles.metaItems}>
              {product.engine_cc !== (0 || "0") && (
                <div className={styles.metaItem}>
                  <img
                    src={engine}
                    alt="cc"
                    className={styles.Icon}
                    loading="lazy"
                  />
                  <div className={styles.IconName}>
                    <h3 className={styles.metaTitle}>Engine</h3>
                    <p className={styles.metaContent}>{product.engine_cc} cc</p>
                  </div>
                </div>
              )}
              <div className={styles.metaItem}>
                <img
                  src={php}
                  alt="php"
                  className={styles.Icon}
                  loading="lazy"
                />
                <div className={styles.IconName}>
                  <h3 className={styles.metaTitle}>bhp</h3>
                  <p className={styles.metaContent}>{product.bhp}</p>
                </div>
              </div>
              <div className={styles.metaItem}>
                <img
                  src={seat}
                  alt="seat"
                  className={styles.Icon}
                  loading="lazy"
                />
                <div className={styles.IconName}>
                  <h3 className={styles.metaTitle}>seats</h3>
                  <p className={styles.metaContent}>{product.seats}</p>
                </div>
              </div>
              {product.fuel_capacity && (
                <div className={styles.metaItem}>
                  <img
                    src={fuel}
                    alt="fuel"
                    className={styles.Icon}
                    loading="lazy"
                  />
                  <div className={styles.IconName}>
                    <h3 className={styles.metaTitle}>fuel capacity</h3>
                    <p className={styles.metaContent}>
                      {product.fuel_capacity} L
                    </p>
                  </div>
                </div>
              )}
              <div className={styles.metaItem}>
                <img
                  src={type}
                  alt="type"
                  className={styles.Icon}
                  loading="lazy"
                />
                <div className={styles.IconName}>
                  <h3 className={styles.metaTitle}>Body type</h3>
                  <p className={styles.metaContent}>
                    {product.body_type && BodyType[product.body_type]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* features */}
          {product.description && (
            <div className={styles.description}>
              <div
                className={styles.descBox} // Assuming you have some CSS styles
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked(product.description)),
                }} // Render sanitized HTML directly
              />
            </div>
          )}

          {product.feature_set && product.feature_set.length > 0 && (
            <>
              <Features variants={product.variants} modelName={product.name} />
            </>
          )}

          <div id="faq" className={styles.faq}>
            <h2 className={styles.faqHeading}>Frequently Asked Question</h2>
            <Faq />
          </div>
        </Container>
      )}

      <ErrorBoundary
        onError={(error) => {
          console.error(error);
        }}
      >
        <Drawer
          isVisible={showVariantSelector}
          onClose={() => {
            setShowVariantSelector(false);
            history.push(`/${product.slug}`);
          }}
        >
          <Container fluid ref={variantEl}>
            <div className={styles.filterTitle}>
              {showVariantSelector ? (
                <Button
                  className={styles.closeBtn}
                  variant="light"
                  onClick={() => {
                    setShowVariantSelector(false);
                    history.push(`/${product.slug}`);
                  }}
                >
                  <CloseSVG />
                </Button>
              ) : null}
              <div className={styles.variantName}>
                <h3>{product.name} variants </h3>
                <div className={styles.variantFilter}>
                  {Type && (
                    <>
                      {Type.map((type) => (
                        <div
                          key={type}
                          className={
                            transmission_type.includes(type)
                              ? `${styles.selected} ${styles.bodyType} `
                              : `${styles.bodyType}`
                          }
                          onClick={() =>
                            TypeHandler(
                              transmission_type,
                              setTransmission_type,
                              type
                            )
                          }
                        >
                          <p className={styles.bodyname}>
                            {TransmissionType[type]}
                          </p>
                        </div>
                      ))}
                    </>
                  )}{" "}
                  {Fuel && (
                    <>
                      {Fuel.map((type) => (
                        <div
                          key={type}
                          className={
                            fuel_type.includes(type)
                              ? `${styles.selected} ${styles.bodyType} `
                              : `${styles.bodyType}`
                          }
                          onClick={() =>
                            TypeHandler(fuel_type, setFuel_type, type)
                          }
                        >
                          <p className={styles.bodyname}>{FuelType[type]}</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <Row>
              <Col md={12}>
                <VariantSelector
                  variants={filteredVariants}
                  showPrice={true}
                  product={product}
                />
              </Col>
            </Row>
          </Container>
          <div className={styles.selector_btn_wrapper_fill} />
          <div className={styles.selector_btn_wrapper}>
            <Button
              type="primary"
              onClick={checkout}
              disabled={!get(activeProductDetails, ["variantId"])}
            >
              {get(activeProductDetails, ["variantId"])
                ? "Proceed"
                : "Choose a variant"}
            </Button>
          </div>
        </Drawer>
      </ErrorBoundary>
    </>
  );
};

const ActiveImage = ({ product, ...props }) => {
  const activeProductDetails = useSelector(selectActiveModel);
  const activeImage =
    activeProductDetails.colorId && get(product, "colors", []).length
      ? get(
          get(product, "colors", []).find(
            (img) => img.id === activeProductDetails.colorId
          ),
          "display_image"
        )
      : product.main_image;
  return (
    <div className={styles.img_wrapper}>
      <Image src={activeImage} alt={product.name} {...props} />
      <p className={styles.colorName}>
        {get(activeProductDetails, ["colorName"])}
      </p>
    </div>
  );
};

const CloseSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1489 17.7853C17.0317 17.9025 16.8418 17.9025 16.7246 17.7853L6.213 7.27371C6.09584 7.15655 6.09584 6.9666 6.213 6.84944L6.84939 6.21305C6.96655 6.09589 7.1565 6.09589 7.27366 6.21305L17.7853 16.7247C17.9024 16.8418 17.9024 17.0318 17.7853 17.1489L17.1489 17.7853Z"
      fill="#303335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21071 17.1521C6.09355 17.0349 6.09355 16.8449 6.21071 16.7278L16.7221 6.21641C16.8392 6.09926 17.0292 6.09926 17.1463 6.21641L17.7827 6.85281C17.8999 6.96997 17.8999 7.15992 17.7827 7.27707L7.27137 17.7884C7.15421 17.9056 6.96426 17.9056 6.8471 17.7884L6.21071 17.1521Z"
      fill="#303335"
    />
  </svg>
);
export default Product;
