import React from "react";
import { Carousel } from "react-bootstrap";
import { ImageWithoutStyles, SimpleImage } from "../../atoms/Image/Image";
import styles from "./styles.module.css";
const Banners = ({ banners }) => {
  return (
    <Carousel className={styles.carouselcontainer}>
      {banners.map((banner) => (
        <Carousel.Item key={banner.id} interval={1000}>
          <a href={`${banner.link}`}>
            <ImageWithoutStyles
              className={styles.imagewrapper}
              src={banner.image}
              alt={banner.link}
            />
          </a>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
export const ImageBanners = ({ banners }) => {
  return (
    <Carousel className={styles.container}>
      {banners.map((banner) => (
        <Carousel.Item key={banner.picture} interval={1000}>
          <SimpleImage
            className={styles.imagewrapper}
            src={banner.picture}
            alt={banner.picture}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Banners;
