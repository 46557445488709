import { getApi, postApi, getApiResponse } from "./utils";

export const getBanners = () => {
  return getApi("banners");
};

export const getModels = () => {
  return getApi("models", { per_page: 100 });
};

export const searchModels = (params) => {
  return getApi("models/search", params);
};
// export const searchModels = (params) => {
//   return getApiResponse("models/search", params);
// };

export const getBrands = () => {
  return getApi("brands");
};

export const getModel = ({ id, location }) => {
  return getApiResponse(`models/${id}`, location);
};

export const getDealers = ({ brand_id }) => {
  return getApi(`dealers`, { brand_id: brand_id });
};

export const getDealer = ({ id }) => {
  return getApi(`dealers/${id}`);
};
export const getShowrooms = ({ dealer_id }) => {
  return getApi(`dealers/${dealer_id}/showrooms`);
};
export const getOnRoadPriceForDealer = ({ id, variantId }) => {
  return postApi(`dealers/${id}/on_road_price`, { variant_id: variantId });
};

export const createOrder = (activeModel) => {
  return postApi(`payments/init`, {
    variant_id: activeModel.variantId,
    dealer_id: activeModel.dealerId,
    showroom_id: activeModel.showroomId,
    color_id: activeModel.colorId,
    optional_fittings: false,
  });
};

export const verifyOrder = (order) => {
  return postApi(`payments/success`, {
    booking_id: order.booking_id,
    razorpay_order_id: order.razorpay_order_id,
    razorpay_payment_id: order.razorpay_payment_id,
    razorpay_signature: order.razorpay_signature,
  });
};
