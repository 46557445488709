import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col, Button, Form } from "react-bootstrap";

import { FuelType, TransmissionType } from "../home/data";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import { selectModelData, loadModelFromAPI } from "../model/modelSlice";
import Loading from "../../components/atoms/loading/Loading";
import { changeNumberFormat, onRoadPrice } from "../../utils";
import { SimpleImage } from "../../components/atoms/Image/Image";
import styles from "./Offer.module.css";
import { filterArray } from "../home/Utils";
import { logout, selectUserData } from "../login/loginSlice";
import {
  createLeadApi,
  FinanceApi,
  selectFinanceData,
  FinanceLeadApi,
  selectFinanceLeadLoading,
} from "./offerSclice";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./loading.json";
import LoginPopUp from "../login/LoginPopUp";
import close from "./SVG/close.svg";
import offer from "./SVG/offer.svg";
import dropdown from "./SVG/dropdown.svg";
import click from "./SVG/click.svg";
import carbae from "./SVG/carbae.svg";
import finance from "./SVG/finance.svg";
import delivery from "./SVG/delivery.svg";
import Countdown from "../../components/molecules/CountDown/CountDown";

export const FinancePrice = {
  ex_showroom_price: "Ex Showroom Price",
  on_road_amount: "On Road Amount",
  on_road_amount_with_optional: "On Road Amount with Optional",
};

const Offers = () => {
  const { productId } = useParams();

  const userData = useSelector(selectUserData);
  const [apiCall, setApiCall] = useState(false);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginForm, setLoginForm] = useState(false);
  const [loginFormFinance, setLoginFormFinance] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerDisplay, setOfferDisplay] = useState(false);
  const [financeLeadDisplay, setFinanceLeadDisplay] = useState(false);
  const [openVaraints, setOpenVaraints] = useState(false);

  const dispatch = useDispatch();
  const location = useSelector(selectLocationData);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(
      loadModelFromAPI(
        productId,
        location?.lat &&
          location?.lng && {
            lat: location?.lat,
            lng: location?.lng,
          }
      )
    );
  }, [dispatch, productId, location.lat, location.lng]);

  const product = useSelector(selectModelData);

  const [Fuel, setFuel] = useState("");
  const [Type, setType] = useState("");

  const [filteredVariants, setFilteredVariants] = useState([]);
  const [transmission_type, setTransmission_type] = useState("");
  const [fuel_type, setFuel_type] = useState("");

  const TypeHandler = (datasource, SetDataSource, type) => {
    if (datasource.indexOf(type) !== -1) {
      SetDataSource(datasource.filter((value) => value !== type));
    } else {
      SetDataSource((brandId) => [...brandId, type]);
    }
  };

  useEffect(() => {
    if (product.variants) {
      setFuel([
        ...new Set(product.variants.map((model) => model["fuel_type"])),
      ]);
      setType([
        ...new Set(product.variants.map((model) => model["transmission_type"])),
      ]);
      const filteredVariants = filterArray(product.variants, {
        transmission_type,
        fuel_type,
      });
      setFilteredVariants(filteredVariants);
    }
  }, [product.variants, fuel_type, transmission_type]);

  const callBackHandler = useCallback(() => {
    const payload = {
      dealer_id: product.dealer.id,
      showroom_id: product.showroom.id,
      model_id: product.id,
      // variant_id: 1,
    };
    dispatch(createLeadApi(payload));
    setApiCall(true);
    setApiCallSuccess(true);
  }, [dispatch, product]);

  const LoginHandler = () => {
    setLoginForm(true);
  };

  useEffect(() => {
    if (loginForm && userData?.session && userData.session["access-token"]) {
      setLoginForm(false);
      callBackHandler();
    }
  }, [userData, loginForm, callBackHandler]);

  useEffect(() => {
    if (!!product?.offers?.length) {
      const offer = product.offers.map((item) => item);
      setOffers(offer);
    }
  }, [product.offers]);

  const ref = useRef(null);
  const scroll = (scrollOffset, ref) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const baseModal = product?.variants
    .map((res) => res)
    .sort(
      (a, b) =>
        a?.price?.on_road_amount_with_optional -
        b?.price?.on_road_amount_with_optional
    )[0];

  const [selected, setSelected] = useState(baseModal);

  const VariantSelected = (id) => {
    const SelectedVariant = product.variants.find((variant) => {
      return variant.id === id;
    });
    setSelected(SelectedVariant);
    if (window.innerWidth < 768) {
      setOpenVaraints(false);
    }
  };

  useEffect(() => {
    if (!!selected && !!product.dealer?.id && !!product.showroom?.id) {
      const payload = {
        dealer_id: product.dealer.id,
        showroom_id: product.showroom.id,
        model_id: product.id,
        variant_id: selected.id,
      };
      dispatch(FinanceApi(payload));
    }
  }, [
    selected,
    dispatch,
    product.showroom?.id,
    product.dealer?.id,
    product.id,
  ]);

  const financeData = useSelector(selectFinanceData);

  const financeLeadLoading = useSelector(selectFinanceLeadLoading);

  const [data, setData] = useState({
    down_payment: "",
    loan_amount: "",
    tenure: "",
    emi_approx: "",
  });

  const submitHandler = useCallback(() => {
    const payload = {
      model_id: product?.id,
      dealer_id: product?.dealer?.id,
      showroom_id: product?.showroom?.id,
      variant_id: selected?.id,
      ...data,
    };
    dispatch(FinanceLeadApi(payload));
  }, [data, product, selected, dispatch]);

  const LoginHandlerFinance = () => {
    setLoginFormFinance(true);
  };

  useEffect(() => {
    if (
      loginFormFinance &&
      userData?.session &&
      userData.session["access-token"]
    ) {
      setLoginFormFinance(false);
      submitHandler();
    }
  }, [userData, loginFormFinance, submitHandler]);

  const getTectDriveLabel = (text, delimiter = " | ") => {
    const parts = text.split(delimiter);

    if (parts.length > 0 && parts[0].trim()) {
      return parts[0].trim();
    }

    return "";
  };

  return (
    <>
      {product.id ? (
        <div
          className={`${styles.offerContainer} ${
            loading && location.lng && location.lat ? styles.loading : ""
          }`}
        >
          {loading && location.lng && location.lat && (
            <div className={styles.loadingScreen}>
              <Player
                autoplay
                loop
                src={animationData}
                className={styles.loadingAnm}
              />
              <p className={styles.loadingText}>
                Finding The Best Showroom Near You. . . . .
              </p>
            </div>
          )}
          {loginForm && (
            <div className={styles.loginScreen}>
              <div className={styles.shade}>
                <div className={styles.closeContainer}>
                  <div className={styles.loginHeader}>
                    <h3 className={styles.loginHeading}>
                      Your Special Offer Awaits! Log in to proceed.
                    </h3>
                    <img
                      src={close}
                      alt="close"
                      loading="lazy"
                      className={styles.clearBtn}
                      onClick={() => {
                        setLoginForm(false);
                        dispatch(logout());
                      }}
                    />
                  </div>
                </div>
                <LoginPopUp />
              </div>
            </div>
          )}
          {loginFormFinance && (
            <div className={styles.loginScreen}>
              <div className={styles.shade}>
                <div className={styles.closeContainer}>
                  <div className={styles.loginHeader}>
                    <h3 className={styles.loginHeading}>
                      Custome Financing Options! Made Just For You Log in for
                      the best rates
                    </h3>
                    <img
                      src={close}
                      alt="close"
                      loading="lazy"
                      className={styles.clearBtn}
                      onClick={() => {
                        setLoginFormFinance(false);
                        dispatch(logout());
                      }}
                    />
                  </div>
                </div>
                <LoginPopUp />
              </div>
            </div>
          )}
          <Helmet>
            <title>
              {product.name} On Road Price And Offers
              {product?.showroom && product?.showroom?.place
                ? ` In ${product.showroom.place}`
                : ""}
            </title>
            <meta
              name="description"
              content={`Get Exciting  Offers for ${product.name} at Carbae.in. `}
            />
            <meta
              name="keywords"
              content={`${product.name} Offers,Get Exciting Offers `}
            />
          </Helmet>
          {apiCallSuccess && (
            <div
              className={styles.modal}
              onClick={() => setApiCallSuccess(false)}
            >
              <span
                onClick={() => setApiCallSuccess(false)}
                className={styles.btn}
              >
                &times;
              </span>
              <div className={styles.content}>
                <div>
                  <p>
                    Thank You for contacting {product.dealer.name} -{" "}
                    {product.showroom.place}.
                  </p>
                  <p>We will contact you soon to assist you further.</p>
                </div>
                <div
                  onClick={() => setApiCallSuccess(false)}
                  className={styles.close}
                >
                  Okay
                </div>
              </div>
            </div>
          )}
          <Row>
            <Col md="5">
              <div className={styles.cont}>
                {openVaraints ? (
                  <div className={styles.varaintContainer}>
                    <div className={styles.closebtn}>
                      <img
                        onClick={() => setOpenVaraints(false)}
                        src={close}
                        alt="close"
                        loading="lazy"
                      />
                    </div>

                    <div className={styles.variantFilter}>
                      {Type && (
                        <>
                          {Type.map((type) => (
                            <div
                              key={type}
                              className={
                                transmission_type.includes(type)
                                  ? `${styles.selected} ${styles.bodyType} `
                                  : `${styles.bodyType}`
                              }
                              onClick={() =>
                                TypeHandler(
                                  transmission_type,
                                  setTransmission_type,
                                  type
                                )
                              }
                            >
                              <p className={styles.bodyname}>
                                {TransmissionType[type]}
                              </p>
                            </div>
                          ))}
                        </>
                      )}{" "}
                      {Fuel && (
                        <>
                          {Fuel.map((type) => (
                            <div
                              key={type}
                              className={
                                fuel_type.includes(type)
                                  ? `${styles.selected} ${styles.bodyType} `
                                  : `${styles.bodyType}`
                              }
                              onClick={() =>
                                TypeHandler(fuel_type, setFuel_type, type)
                              }
                            >
                              <p className={styles.bodyname}>
                                {FuelType[type]}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <div className={styles.varaintList}>
                      {filteredVariants
                        .sort(
                          (a, b) =>
                            a?.price?.on_road_amount_with_optional -
                            b?.price?.on_road_amount_with_optional
                        )
                        .map((variant) => (
                          <div
                            key={variant.id}
                            className={
                              variant.id === selected.id
                                ? `${styles.variant} ${styles.selectedModel}`
                                : styles.variant
                            }
                            onClick={() => VariantSelected(variant.id)}
                          >
                            {variant.price && (
                              <div>
                                <div className={styles.qstn}>
                                  <p>{variant.name}</p>
                                  <p>
                                    {changeNumberFormat(
                                      variant.ex_showroom_starting
                                    )}
                                  </p>
                                </div>
                                <div className={styles.variantDetails}>
                                  {variant.transmission_type &&
                                    `${
                                      TransmissionType[
                                        variant.transmission_type
                                      ]
                                    }  `}
                                  {variant.fuel_type &&
                                    `, ${FuelType[variant.fuel_type]}  `}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <div className={styles.card}>
                    <SimpleImage
                      src={product.main_image}
                      alt={product.name}
                      className={styles.img}
                    />
                    <div className={styles.cardText}>
                      <div>
                        <h2 className={styles.cardModel}>{product.name}</h2>
                        <h3 className={styles.cardVaraint}>{selected.name}</h3>
                      </div>
                      {product?.showroom && product.dealer && (
                        <div>
                          {userData?.session &&
                          userData.session["access-token"] ? (
                            <Button
                              disabled={apiCall}
                              className={styles.cardCallback}
                              onClick={callBackHandler}
                            >
                              Book Now
                            </Button>
                          ) : (
                            <Button
                              disabled={apiCall}
                              className={styles.cardCallback}
                              onClick={LoginHandler}
                            >
                              Book Now
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col md="7" className={openVaraints && `${styles.notRealtive}`}>
              <div className={styles.cont_left}>
                <h1 className={styles.heading}>{product.name}</h1>
                <p className={`${styles.price} `}>Starting from</p>
                <h2 className={styles.startAmount}>
                  {changeNumberFormat(product.price_starts)}
                </h2>

                {financeData &&
                  financeData?.finance_mapping?.labels &&
                  getTectDriveLabel(financeData?.finance_mapping?.labels) && (
                    <div className={styles.label}>
                      {getTectDriveLabel(financeData?.finance_mapping?.labels)}
                    </div>
                  )}
                {product?.showroom && product.dealer && (
                  <>
                    <p className={styles.congrats}>
                      Congrats! 🎉 We've located a nearby showroom offering the
                      best deal!
                    </p>
                    <div className={styles.dealer}>
                      {product.dealer.logo && (
                        <div className={styles.dealerLogo}>
                          <SimpleImage
                            src={product.dealer.logo}
                            alt={product.dealer.name}
                          />
                        </div>
                      )}
                      <h2 className={styles.showroom}>
                        {product.showroom.name}
                      </h2>
                      <p className={styles.address}>
                        {product.dealer.name}
                        {product.showroom.address_line_1 &&
                          ` , ${product.showroom.address_line_1} , `}
                        {product.showroom.address_line_2 &&
                          `${product.showroom.address_line_2} , `}
                        {product.showroom.city && `${product.showroom.city} , `}
                        {product.showroom.district &&
                          `${product.showroom.district} , `}
                        {product.showroom.pin && `${product.showroom.pin} . `}
                      </p>
                    </div>
                  </>
                )}
              </div>

              {location.lat && location.lng ? (
                <>
                  {!!product?.offers?.length && (
                    <div className={styles.offerBackground}>
                      <div className={styles.offers}>
                        <h2 className={styles.offer_heading}>
                          Offers for you{" "}
                        </h2>
                        {product?.total_offers_upto > 0 && (
                          <>
                            <div className={styles.offerTitle}>
                              <img src={offer} alt="close" loading="lazy" />
                              <p className={`${styles.offer_text}  `}>
                                Total Offers
                              </p>
                            </div>
                            <div className={styles.paddingContainer}>
                              <p className={styles.offerPrice}>
                                {changeNumberFormat(product.total_offers_upto)}
                              </p>
                              <p className={styles.offerText}>
                                Get upto a total of{" "}
                                {changeNumberFormat(product.total_offers_upto)}{" "}
                                discount on {product.name}.
                              </p>
                              {offers[0]?.end_date && (
                                <Countdown endDate={offers[0].end_date} />
                              )}
                              <div className={styles.offerButtons}>
                                {userData?.session &&
                                userData.session["access-token"] ? (
                                  <Button
                                    disabled={apiCall}
                                    className={styles.callbackButton}
                                    onClick={callBackHandler}
                                  >
                                    Get Offer
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={apiCall}
                                    className={styles.callbackButton}
                                    onClick={LoginHandler}
                                  >
                                    Get Offer
                                  </Button>
                                )}

                                {offerDisplay ? (
                                  <div
                                    className={styles.offerDisplayButton}
                                    onClick={() => setOfferDisplay(false)}
                                  >
                                    Hide all offers
                                    <img
                                      src={close}
                                      alt="close"
                                      loading="lazy"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={styles.offerDisplayButton}
                                    onClick={() => setOfferDisplay(true)}
                                  >
                                    View all offers
                                    <img
                                      src={dropdown}
                                      alt="dropdown"
                                      loading="lazy"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {offerDisplay && (
                          <div className={styles.scrollContainer}>
                            {
                              <div
                                className={`${styles.scrollButton} ${styles.scrollButtonPrev}`}
                                onClick={() => scroll(-150, ref)}
                              ></div>
                            }
                            <div
                              className={styles["scrolling-wrapper"]}
                              ref={ref}
                            >
                              {offers
                                .sort((a, b) => b.amount - a.amount)
                                .map((offer) => (
                                  <div key={offer.id} className={styles.offer}>
                                    <h4 className={styles.name}>
                                      {offer.name}
                                    </h4>
                                    <p>
                                      <span className={styles.amount}>
                                        {offer.valuation === "flat" && " Rs "}
                                        {offer.amount}
                                        {offer.valuation === "percentage" &&
                                          " % "}{" "}
                                        off
                                      </span>
                                    </p>
                                    <p className={styles.description}>
                                      {offer.description}
                                    </p>
                                    {offer?.end_date && (
                                      <Countdown endDate={offer.end_date} />
                                    )}
                                    <p className={styles.condition}>
                                      * {offer.offer_type.condition}
                                    </p>
                                  </div>
                                ))}
                            </div>
                            {
                              <div
                                className={`${styles.scrollButton} ${styles.scrollButtonNext}`}
                                onClick={() => scroll(150, ref)}
                              ></div>
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {financeData && financeData?.finance_mapping && (
                    <div className={styles.offers}>
                      <div className={styles.offerTitle}>
                        <img src={finance} alt="close" loading="lazy" />
                        <p className={`${styles.offer_text}  `}>
                          Finance Option
                        </p>
                      </div>
                      <div className={styles.paddingContainer}>
                        <p className={styles.financeText}>
                          Up-to{"  "}
                          <b>
                            {" "}
                            {financeData.finance_mapping.funding_percentage}%
                          </b>{" "}
                          funding available of{" "}
                          {FinancePrice[financeData.finance_mapping.funding_on]}
                        </p>
                        <div className={styles.flex}>
                          {financeData.finance_mapping.down_payment_starts ? (
                            <p className={styles.down_payment_starts}>
                              Down Payment Starts at : ₹{" "}
                              {financeData.finance_mapping.down_payment_starts}
                            </p>
                          ) : null}
                          <p className={styles.details}>
                            Interest :{" "}
                            {financeData.finance_mapping.interest_from}%
                          </p>
                          <p className={styles.details}>
                            Tenure : {financeData.finance_mapping.max_tenure}{" "}
                            years
                          </p>
                        </div>
                        {financeLeadDisplay ? (
                          <div
                            className={styles.financeLead}
                            onClick={() => setFinanceLeadDisplay(false)}
                          >
                            Get Personalized Finance For You
                            <img src={close} alt="close" loading="lazy" />
                          </div>
                        ) : (
                          <div
                            className={styles.financeLead}
                            onClick={() => setFinanceLeadDisplay(true)}
                          >
                            Get Personalized Finance For You
                            <img src={dropdown} alt="dropdown" loading="lazy" />
                          </div>
                        )}
                        {financeLeadDisplay && (
                          <div className={styles.financeForm}>
                            <Form>
                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  Down Payment :
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    className="font-weight-bold"
                                    placeholder="Down Payment"
                                    value={data.down_payment}
                                    onChange={(e) =>
                                      setData({
                                        ...data,
                                        down_payment: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Form.Group>

                              <Form.Group as={Row}>
                                <Form.Label column sm="3">
                                  EMI approx :
                                </Form.Label>
                                <Col sm="9">
                                  <Form.Control
                                    className="font-weight-bold"
                                    placeholder="Enter EMI approx"
                                    value={data.interest_from}
                                    onChange={(e) =>
                                      setData({
                                        ...data,
                                        emi_approx: e.target.value,
                                      })
                                    }
                                  />
                                </Col>
                              </Form.Group>

                              <div className="col text-center mt-5">
                                {userData?.session &&
                                userData.session["access-token"] ? (
                                  <Button
                                    variant="primary"
                                    onClick={submitHandler}
                                  >
                                    {financeLeadLoading
                                      ? "Submitting..."
                                      : "Get Finance Quote"}
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={LoginHandlerFinance}
                                  >
                                    {financeLeadLoading
                                      ? "Submitting..."
                                      : "Get Finance Quote"}
                                  </Button>
                                )}
                              </div>
                            </Form>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {financeData &&
                    financeData?.finance_mapping?.delivery_time && (
                      <div className={styles.offers}>
                        <div className={styles.offerTitle}>
                          <img src={delivery} alt="close" loading="lazy" />
                          <p className={`${styles.offer_text}  `}>
                            Delivery Time
                          </p>
                        </div>
                        <div className={styles.paddingContainer}>
                          <div className={styles.deliveryTime}>
                            {financeData?.finance_mapping?.delivery_time}
                          </div>
                        </div>
                      </div>
                    )}
                  {!!product?.variants?.length &&
                    product?.variants[0]?.price && (
                      <div className={styles.offers}>
                        <h2 className={styles.offer_heading}>
                          On road price break up
                        </h2>
                        <div
                          className={styles.selectedVariant}
                          onClick={() => setOpenVaraints(true)}
                        >
                          <div>
                            <p>Variant Name</p>
                            <h3>{selected.name}</h3>
                          </div>
                          <div>
                            <img src={click} alt="close" loading="lazy" />
                          </div>
                        </div>
                        <div className={styles.priceContainer}>
                          {selected.price.ex_showroom_price ? (
                            <p>
                              <span>Ex-Showroom Price</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.ex_showroom_price}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.tcs ? (
                            <p>
                              <span>TCS</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.tcs}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.rto_amount ? (
                            <p>
                              <span>Road Tax</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.rto_amount}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.insurance_amount ? (
                            <p>
                              <span>Insurance</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.insurance_amount}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.registration_charges ? (
                            <p>
                              <span>Registration</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.registration_charges}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.fast_tag ? (
                            <p>
                              <span>Fast Tag</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.fast_tag}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.extended_warranty ? (
                            <p>
                              <span>Extended Warranty</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.extended_warranty}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.basic_accessories_kit ? (
                            <p>
                              <span>Basic Accessories Kit</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.basic_accessories_kit}
                              </span>
                            </p>
                          ) : null}
                          {selected.price.other_charges ? (
                            <p>
                              <span>Other Charges</span>
                              <span className={styles.price_amount}>
                                ₹ {selected.price.other_charges}
                              </span>
                            </p>
                          ) : null}

                          {selected.price.on_road_amount_with_optional ? (
                            <p className={styles.border}>
                              <span className={` ${styles.onRoadPrice}`}>
                                <b>On Road Price</b>
                              </span>
                              <span
                                className={`${styles.price_amount} ${styles.onRoadPrice}`}
                              >
                                ₹ {onRoadPrice(selected.price)}
                              </span>
                            </p>
                          ) : null}

                          {selected.price.offer_amount ? (
                            <p>
                              <span className={` ${styles.onRoadPrice}`}>
                                <b> Offer Price</b>
                              </span>
                              <span
                                className={`${styles.price_amount} ${styles.onRoadPrice}`}
                              >
                                - ₹ {selected.price.offer_amount}
                              </span>
                            </p>
                          ) : null}

                          {selected.price.offer_amount ? (
                            <p className={styles.border}>
                              <span className={` ${styles.onRoadPrice}`}>
                                <b> Total Price</b>
                              </span>
                              <span
                                className={`${styles.price_amount} ${styles.onRoadPrice}`}
                              >
                                ₹
                                {onRoadPrice(selected.price) -
                                  selected.price.offer_amount}
                              </span>
                            </p>
                          ) : null}

                          <div className={styles.Pricedealer}>
                            <h2 className={styles.showroom}>
                              {product.dealer.name} {product.showroom.place}
                            </h2>
                            <p className={styles.address}>
                              {product.showroom.name &&
                                `${product.showroom.name} , `}
                              {product.showroom.address_line_1 &&
                                `${product.showroom.address_line_1} , `}
                              {product.showroom.city &&
                                `${product.showroom.city} , `}
                              {product.showroom.district &&
                                `${product.showroom.district} , `}
                              {product.showroom.state &&
                                `${product.showroom.state} .`}
                            </p>
                            <div className={styles.logos}>
                              {product.dealer.logo && (
                                <div className={styles.dealerLogo}>
                                  <SimpleImage
                                    src={product.dealer.logo}
                                    alt={product.dealer.name}
                                  />
                                </div>
                              )}
                              <img src={carbae} alt="carbae" loading="lazy" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  {product?.showroom && product.dealer && (
                    <div className={styles.callbackButtonBottom}>
                      <h1
                        className={`${styles.heading} ${styles.bottomButtom}`}
                      >
                        {selected.name}
                      </h1>

                      {userData?.session && userData.session["access-token"] ? (
                        <Button
                          disabled={apiCall}
                          className={styles.cardCallback}
                          onClick={callBackHandler}
                        >
                          Book Now
                        </Button>
                      ) : (
                        <Button
                          disabled={apiCall}
                          className={styles.cardCallback}
                          onClick={LoginHandler}
                        >
                          Book Now
                        </Button>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <h4 className={styles.h4}>
                  Please Enable Location To Get The Best Offers From The Best
                  Showrooms Near You
                </h4>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Offers;
