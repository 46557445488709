import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectResults } from "../home/filterSlice";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Filter from "../home/Filter";
import styles from "./Search.module.css";
import {
  ProdCardSkelton,
  ProductCard,
} from "../../components/molecules/prodCard/ProdCard";
import FilterSvg from "./Filter.svg";
const Search = () => {
  const [show, setShow] = useState(false);

  const results = useSelector(selectResults);

  return (
    <>
      <Container className={styles.containerSearch}>
        {!results.length && (
          <h2 className="center mt-three">No models found here</h2>
        )}
        {!results.length ? (
          <div className={styles.Skelton}>
            <Row className={styles.models}>
              <Col xs="6" className={styles.card}>
                <ProdCardSkelton />
              </Col>
              <Col xs="6" className={styles.card}>
                <ProdCardSkelton />
              </Col>
            </Row>
          </div>
        ) : (
          <>
            {results && (
              <div className="center">
                <h1 className={styles.heading}>Results</h1>
              </div>
            )}
            <div className={styles.models}>
              {results &&
                results.map((product, index) => (
                  <div className={styles.card} key={`${product.id}/${index}`}>
                    <ProductCard product={product} />
                  </div>
                ))}
            </div>
          </>
        )}
        <div className={styles.searchButton} onClick={() => setShow(true)}>
          <img src={FilterSvg} alt="Filter" loading="lazy" />
        </div>
      </Container>
      {show && (
        <Filter visibility={show} click={() => setShow(false)} clear={true} />
      )}
    </>
  );
};
export default Search;
