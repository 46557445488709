import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import get from "lodash/get";
import { Link } from "react-router-dom";
import {
  selectModelData,
  selectActiveModel,
  loadModelDealerOnRoadPriceFromAPI,
} from "../model/modelSlice";
import { useEffect } from "react";
import { useState } from "react";
import {
  createOrderAPI,
  selectOrderData,
  selectCheckoutError,
  verifyOrderAPI,
} from "./checkoutSlice";
import isEmpty from "lodash/isEmpty";
import { selectUserData } from "../login/loginSlice";
import styles from "./checkout.module.css";
import { ChoosenCar } from "../../components/molecules/ChoosenCar";
import { Heading } from "../../components/atoms/heading";

import Drawer from "react-bottom-drawer";
import { Row, Col, Alert, Container, Button } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import VariantSelector from "../../components/molecules/variantSelector/VariantSelector";
import { SimpleImage } from "../../components/atoms/Image/Image";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import { getGeoCode } from "../../api/user";
import { updateUserAPI } from "../profile/profileSlice";
import { onRoadPrice } from "../../utils";
const Checkout = () => {
  const dispatch = useDispatch();
  const product = useSelector(selectModelData);
  const order = useSelector(selectOrderData);
  const error = useSelector(selectCheckoutError);
  const activeProduct = useSelector(selectActiveModel);
  const userData = useSelector(selectUserData);
  const location = useSelector(selectLocationData);

  useEffect(() => {
    if (
      activeProduct.dealerId &&
      activeProduct.showroomId &&
      activeProduct.priceBreakup?.variant_id !== activeProduct.variantId
    ) {
      dispatch(
        loadModelDealerOnRoadPriceFromAPI(
          activeProduct.dealerId,
          activeProduct.variantId
        )
      );
    }
  }, [
    activeProduct.dealerId,
    activeProduct.priceBreakup,
    activeProduct.variantId,
    activeProduct.variant_id,
    activeProduct.showroomId,
    dispatch,
  ]);
  const [orderCreating, setOrderCreating] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [showVaraint, setShowVaraint] = useState(false);
  const activeDealer =
    product?.dealer?.id === activeProduct.dealerId && product.dealer;
  const activeShowroom =
    product?.showroom?.id === activeProduct.showroomId && product.showroom;
  useEffect(() => {
    const orderDetails = order.booking || {};
    if (
      (isEmpty(order) ||
        activeProduct.colorId !== orderDetails.color_id ||
        activeProduct.dealerId !== orderDetails.dealer_id ||
        activeProduct.showroomId !== orderDetails.showroom_id ||
        activeProduct.variantId !== orderDetails.variant_id) &&
      !orderCreating
    ) {
      setOrderCreating(true);
      dispatch(createOrderAPI(activeProduct));
    }
    if (!isEmpty(order)) {
      setOrderSuccess(true);
    }
  }, [activeProduct, dispatch, order, orderCreating]);

  useEffect(() => {
    if (
      location.lat &&
      location.lng &&
      get(userData, ["session", "access-token"])
    )
      getGeoCode(location.lat, location.lng).then((address) => {
        const payload = {
          lat: location.lat,
          lng: location.lng,
          current_location: address,
        };
        dispatch(updateUserAPI(userData?.userInfo?.id, payload));
      });
  }, [location, userData, dispatch]);

  return (
    <>
      <Container className={styles.container}>
        <Col>
          {product ? (
            <div className={styles.checkout}>
              <Heading>Your Car </Heading>
              <ChoosenCar EditVaraint={() => setShowVaraint(true)} />
              {!orderSuccess && !error ? (
                <p>"Your order is being created..." </p>
              ) : null}
              {activeDealer && activeShowroom && (
                <>
                  <p className={styles.bold}> Dealer </p>
                  <div className={styles.dealerContainer}>
                    {activeDealer.logo && (
                      <div className={styles.dealer}>
                        <SimpleImage
                          src={activeDealer?.logo}
                          alt={activeDealer?.name}
                        />
                      </div>
                    )}
                    <div>
                      <p> {activeDealer?.name}</p>
                      <p> {activeShowroom?.name}</p>
                    </div>
                  </div>
                </>
              )}
              {error.message ? (
                <Alert variant="danger">
                  {error.message}
                  <Link to="/login" className="nav-link">
                    Login
                  </Link>
                </Alert>
              ) : null}
              <Heading>Price Quote </Heading>
              {activeProduct ? (
                <div className={styles.billing}>
                  <p>
                    Ex showroom price:{" "}
                    <span>
                      ₹
                      {get(activeProduct, [
                        "priceBreakup",
                        "ex_showroom_price",
                      ])}
                    </span>
                  </p>
                  {get(activeProduct, ["priceBreakup", "tcs"]) ? (
                    <p>
                      TCS:{" "}
                      <span>
                        ₹{get(activeProduct, ["priceBreakup", "tcs"])}
                      </span>
                    </p>
                  ) : null}
                  <p>
                    Road Tax:{" "}
                    <span>
                      ₹{get(activeProduct, ["priceBreakup", "rto_amount"])}
                    </span>
                  </p>
                  <p>
                    Insurance:{" "}
                    <span>
                      ₹
                      {get(activeProduct, ["priceBreakup", "insurance_amount"])}
                    </span>
                  </p>
                  {get(activeProduct, [
                    "priceBreakup",
                    "registration_charges",
                  ]) ? (
                    <p>
                      Registration charges:{" "}
                      <span>
                        ₹
                        {get(activeProduct, [
                          "priceBreakup",
                          "registration_charges",
                        ])}
                      </span>
                    </p>
                  ) : null}
                  {get(activeProduct, ["priceBreakup", "fast_tag"]) ? (
                    <p>
                      Fastag:{" "}
                      <span>
                        ₹{get(activeProduct, ["priceBreakup", "fast_tag"])}
                      </span>
                    </p>
                  ) : null}
                  {get(activeProduct, ["priceBreakup", "extended_warranty"]) ? (
                    <p>
                      Extended warranty:{" "}
                      <span>
                        ₹
                        {get(activeProduct, [
                          "priceBreakup",
                          "extended_warranty",
                        ])}
                      </span>
                    </p>
                  ) : null}
                  {get(activeProduct, [
                    "priceBreakup",
                    "basic_accessories_kit",
                  ]) ? (
                    <p>
                      Basic accessories kit:{" "}
                      <span>
                        ₹
                        {get(activeProduct, [
                          "priceBreakup",
                          "basic_accessories_kit",
                        ])}
                      </span>
                    </p>
                  ) : null}
                  {get(activeProduct, ["priceBreakup", "discount"]) ? (
                    <p>
                      <span className={styles.font_bold}> Discount: </span>
                      <span>
                        ₹{get(activeProduct, ["priceBreakup", "discount"])}
                      </span>
                    </p>
                  ) : null}
                  {get(activeProduct, ["priceBreakup", "other_charges"]) ? (
                    <p>
                      Other charges:{" "}
                      <span>
                        ₹{get(activeProduct, ["priceBreakup", "other_charges"])}
                      </span>
                    </p>
                  ) : null}
                  <div className={styles.limiter} />
                  {get(activeProduct, [
                    "priceBreakup",
                    "on_road_amount_with_optional",
                    // (optional_charges) in place of  (on_road_amount_with_optional)
                  ]) ? (
                    // [
                    // <p>
                    //   Optional charges:{" "}
                    //   <span>
                    //     ₹
                    //     {get(activeProduct, [
                    //       "priceBreakup",
                    //       "optional_charges",
                    //     ])}
                    //   </span>
                    // </p>,
                    <p>
                      On road amount:{" "}
                      <span>
                        ₹
                        {/* {get(activeProduct, [
                          "priceBreakup",
                          "on_road_amount_with_optional",
                        ])} */}
                        {onRoadPrice(activeProduct.priceBreakup)}
                      </span>
                    </p>
                  ) : (
                    // ]
                    <p>
                      On road amount:{" "}
                      <span>
                        ₹
                        {get(activeProduct, ["priceBreakup", "on_road_amount"])}
                      </span>
                    </p>
                  )}
                </div>
              ) : (
                "Data Not Found. Please Reload"
              )}

              <div>
                <p className={`muted ${styles.info}`}>
                  <span className={styles.bold}> Booking Amount : </span>
                  <span>
                    ₹
                    {get(activeProduct, ["priceBreakup", "min_booking_amount"])}
                  </span>
                </p>
                <PayWithRazorPay
                  amount={`₹${get(activeProduct, [
                    "priceBreakup",
                    "min_booking_amount",
                  ])}`}
                />
              </div>
            </div>
          ) : null}
        </Col>
      </Container>

      <ErrorBoundary
        onError={(error) => {
          console.error(error);
        }}
      >
        <Drawer
          isVisible={showVaraint}
          onClose={() => {
            setShowVaraint(false);
          }}
        >
          <Container fluid className={`${styles.variants}`}>
            <div className={styles.title}>
              {showVaraint ? (
                <Button
                  className={styles.closeBtn}
                  variant="light"
                  onClick={() => {
                    setShowVaraint(false);
                  }}
                >
                  <CloseSVG />
                </Button>
              ) : null}
              <div className={styles.title_wrapper_fill} />
              <h3>{product.name} variants</h3>
            </div>
            <Row>
              <Col md={12}>
                <VariantSelector
                  showPrice={true}
                  variants={product.variants}
                  product={product}
                />
              </Col>
            </Row>
          </Container>
          <div className={styles.selector_btn_wrapper_fill} />
          <div className={styles.selector_btn_wrapper}>
            <Button type="primary" onClick={() => setShowVaraint(false)}>
              Done
            </Button>
          </div>
        </Drawer>
      </ErrorBoundary>
    </>
  );
};

const PayWithRazorPay = ({ amount = "" }) => {
  const { booking = {} } = useSelector(selectOrderData);
  const { userInfo = {} } = useSelector(selectUserData);
  const history = useHistory();
  const dispatch = useDispatch();
  const options = {
    key: process.env.PAYMENT_GATEWAY_TOKEN,
    name: "Carbae",
    description: "Book latest car online from the best dealers near you",
    order_id: booking.order_id,
    image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
    handler: function (response) {
      dispatch(
        verifyOrderAPI(
          {
            booking_id: booking.id,
            ...response,
          },
          history.push
        )
      );
    },
    prefill: {
      name: userInfo.first_name,
      contact: userInfo.phone,
      email: userInfo.email,
    },
    notes: {
      address: "",
    },
    modal: {
      ondismiss: function () {
        console.log("Checkout form closed");
      },
    },
    theme: {
      color: "#ff4f4f",
    },
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  const openPayModal = () => {
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.error", function (resp) {
      // console.log(resp);
      alert(resp.error.description);
    });
    rzp1.open();
  };
  return (
    <div className="center">
      <Button onClick={openPayModal} disabled={isEmpty(booking)}>
        {isEmpty(booking) ? "Please wait..." : `Pay ${amount}`}
      </Button>
    </div>
  );
};

const CloseSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1489 17.7853C17.0317 17.9025 16.8418 17.9025 16.7246 17.7853L6.213 7.27371C6.09584 7.15655 6.09584 6.9666 6.213 6.84944L6.84939 6.21305C6.96655 6.09589 7.1565 6.09589 7.27366 6.21305L17.7853 16.7247C17.9024 16.8418 17.9024 17.0318 17.7853 17.1489L17.1489 17.7853Z"
      fill="#303335"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21071 17.1521C6.09355 17.0349 6.09355 16.8449 6.21071 16.7278L16.7221 6.21641C16.8392 6.09926 17.0292 6.09926 17.1463 6.21641L17.7827 6.85281C17.8999 6.96997 17.8999 7.15992 17.7827 7.27707L7.27137 17.7884C7.15421 17.9056 6.96426 17.9056 6.8471 17.7884L6.21071 17.1521Z"
      fill="#303335"
    />
  </svg>
);
export default Checkout;
