export const validateMobileEmailForm = (values) => {
  const errors = {};
  // if (!values.email) {
  //   errors.email = "Required";
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  //   errors.email = "Invalid email address";
  // }
  if (!values.mobile) {
    errors.mobile = "Required";
  } else if (!/^[0-9._%+-]{10,}$/i.test(values.mobile)) {
    errors.mobile = "Invalid mobile number";
  }
  return errors;
};
export const validateOTP = (values) => {
  const errors = {};
  if (!values.otp) {
    errors.otp = "Required";
  } else if (!/^[0-9._%+-]{6,}$/i.test(values.otp)) {
    errors.otp = "Invalid OTP";
  }
  return errors;
};
