import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "location",
  initialState: {
    data: { lat: null, lng: null },
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    LocationSuccess: (state, action) => {
      state.data = action.payload;
      state.error = {
        message: "",
        haveError: false,
      };
    },

    LocationFailed: (state, action) => {
      state.data = { lat: null, lng: null };
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
  },
});

export const selectLocationData = (state) => state.location.data || {};
export const selectLocationErr = (state) => state.location.error || {};

export const { LocationSuccess, LocationFailed } = slice.actions;

export default slice.reducer;
