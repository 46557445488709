import React from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import styles from "./styles.module.css";
import {
  setActiveModel,
  selectActiveModel,
} from "../../../features/model/modelSlice";
import { changeNumberFormat } from "../../../utils";

//import './styles.css';
const VariantSelector = ({
  variants = [],
  showPrice,
  onVariantSelect,
  ...props
}) => {
  const orderedVariants = [...variants].sort((a, b) => {
    if (a.ex_showroom_starting < b.ex_showroom_starting) {
      return -1;
    }
    if (a.ex_showroom_starting > b.ex_showroom_starting) {
      return 1;
    }
    return 0;
  });
  return (
    <div className={styles.wrapper}>
      {orderedVariants.map((variant) => (
        <Variant
          showPrice={showPrice}
          variant={variant}
          key={variant.id}
          onVariantSelect={onVariantSelect}
          {...props}
        />
      ))}
    </div>
  );
};

const Variant = ({
  variant,
  product,
  showPrice,
  onVariantSelect,
  ...props
}) => {
  const dispatch = useDispatch();
  const activeProductDetails = useSelector(selectActiveModel);
  // console.log(activeProductDetails);
  // TODO: Remove hardcoded variant ID
  const onSelect = (variantId) => {
    dispatch(
      setActiveModel({
        type: "variantId",
        data: variantId,
      })
    );
  };

  return variant.name ? (
    <Card
      className={`${styles.variant} ${
        get(activeProductDetails, ["variantId"]) === variant.id
          ? styles.variantSelected
          : ""
      }`}
      onClick={() => onSelect(variant.id)}
      {...props}
    >
      <Card.Body>
        <Card.Title className={styles.variant_name}>
          {variant.name}
          {showPrice && (
            <span className={styles.variant_price}>
              {changeNumberFormat(variant.ex_showroom_starting)}
            </span>
          )}
        </Card.Title>
        <Card.Subtitle
          className={`${styles.variant_location} ${styles.variant__sub}`}
        >
          {variant.transmission_type} <span></span> {variant.fuel_type}{" "}
          {variant.subname ? (
            <>
              <span></span> {variant.subname}
            </>
          ) : (
            ""
          )}
        </Card.Subtitle>
      </Card.Body>
    </Card>
  ) : null;
};
export default VariantSelector;
