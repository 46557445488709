/**
 * Returns a number in Indian numbering system as a String
 *
 * @param {Number/String} number The integer to be converted.
 * @param {Number} decimals The number of digits needed after decimal point.
 * @return {String} Converted number as a String in Indian numbering unit.
 */

function changeNumberFormat(number, decimals, recursiveCall) {
  const decimalPoints = decimals || 2;
  const noOfLakhs = number / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  }

  if (noOfLakhs >= 1 && noOfLakhs < 100) {
    const lakhs = roundOf(noOfLakhs);
    isPlural = lakhs > 1 && !recursiveCall;
    displayStr = `${lakhs} Lakh${isPlural ? "s" : ""}`;
  } else if (noOfLakhs >= 100) {
    const crores = roundOf(noOfLakhs / 100);
    const crorePrefix =
      crores >= 100000 ? changeNumberFormat(crores, decimals, true) : crores;
    isPlural = crores > 1 && !recursiveCall;
    displayStr = `${crorePrefix} Crore${isPlural ? "s" : ""}`;
  } else {
    displayStr = number;
  }

  return "₹" + displayStr;
}
function PriceRange(start, end, decimals, recursiveCall) {
  const decimalPoints = decimals || 2;
  const noOfLakhsStart = start / 100000;
  const noOfLakhsEnd = end / 100000;
  let displayStr;
  let isPlural;

  // Rounds off digits to decimalPoints decimal places
  function roundOf(integer) {
    return +integer.toLocaleString(undefined, {
      minimumFractionDigits: decimalPoints,
      maximumFractionDigits: decimalPoints,
    });
  }

  if (
    noOfLakhsStart >= 1 &&
    noOfLakhsStart < 100 &&
    noOfLakhsEnd >= 1 &&
    noOfLakhsEnd < 100
  ) {
    const Startlakhs = roundOf(noOfLakhsStart);
    const Endlakhs = roundOf(noOfLakhsEnd);
    isPlural = Startlakhs > 1 && Endlakhs > 1 && !recursiveCall;
    displayStr = `${Startlakhs} - ${Endlakhs} Lakh${isPlural ? "s" : ""}`;
  } else if (noOfLakhsStart < 100 && noOfLakhsEnd >= 100) {
    const Startlakhs = roundOf(noOfLakhsStart);
    const Endcrores = roundOf(noOfLakhsEnd / 100);
    isPlural = Endcrores > 1 && !recursiveCall;
    displayStr = `${Startlakhs} Lakhs - ${Endcrores} Crore${
      isPlural ? "s" : ""
    }`;
  } else if (noOfLakhsStart >= 100 && noOfLakhsEnd >= 100) {
    const Startcrores = roundOf(noOfLakhsStart / 100);
    const Endcrores = roundOf(noOfLakhsEnd / 100);
    isPlural = Startcrores > 1 && Endcrores > 1 && !recursiveCall;
    displayStr = `${Startcrores} - ${Endcrores}  Crore${isPlural ? "s" : ""}`;
  } else {
    displayStr = ` ${roundOf(+start)} - ${roundOf(+end)} `;
  }

  return "₹ " + displayStr;
}

const onRoadPrice = (price) => {
  return (
    price.ex_showroom_price +
    price.tcs +
    price.insurance_amount +
    price.rto_amount +
    price.registration_charges +
    price.fast_tag +
    price.extended_warranty +
    price.basic_accessories_kit +
    price.other_charges
  );
};

export { changeNumberFormat, PriceRange, onRoadPrice };
