import React from "react";
import Card from "react-bootstrap/Card";
import Skeleton from "react-loading-skeleton";
import Image, { ImageSkelton } from "../../atoms/Image/Image";

import styles from "./brandCard.module.css";

const BrandCard = ({ brand = {}, ...props }) => {
  return (
    <div className={styles.card} {...props}>
      <div className={styles.image}>
        <Image variant="top" src={brand.logo} loading="lazy" alt={brand.name} />
      </div>
      <Card.Title>{brand.name}</Card.Title>
    </div>
  );
};

export const BrandCardSkelton = ({ ...props }) => {
  return (
    <div className={styles.card} {...props}>
      <div className={styles.image}>
        <ImageSkelton variant="top" />
      </div>
      <Card.Title>
        <Skeleton count={2} />
      </Card.Title>
    </div>
  );
};
export default BrandCard;
