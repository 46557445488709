import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import homeReducer from "./features/home/homeSlice";
import brandReducer from "./features/brand/brandSlice";
import userReducer from "./features/login/loginSlice";
import modelReducer from "./features/model/modelSlice";
import checkoutReducer from "./features/checkout/checkoutSlice";
import testdriveReducer from "./features/testdrive/testDriveSlice";
import filterReducer from "./features/home/filterSlice";
import locationReducer from "./components/molecules/Location/locationSlice";
import offerReducer from "./features/offers/offerSclice";
import userProfileReducer from "./features/profile/profileSlice";
import { save, load } from "redux-localstorage-simple";

export default configureStore({
  reducer: {
    home: homeReducer,
    brands: brandReducer,
    user: userReducer,
    model: modelReducer,
    checkout: checkoutReducer,
    testdrive: testdriveReducer,
    filter: filterReducer,
    location: locationReducer,
    offer: offerReducer,
    userProfile: userProfileReducer,
  },
  middleware: [
    ...getDefaultMiddleware(),
    save({ states: ["user", "testdrive", "model", "location", "checkout"] }),
  ],
  preloadedState: {
    ...load({ states: ["user", "testdrive", "model", "location", "checkout"] }),
  },
});
