import { createSlice } from "@reduxjs/toolkit";
import {
  getModel,
  getDealers,
  getShowrooms,
  getOnRoadPriceForDealer,
} from "../../api/models";

export const slice = createSlice({
  name: "model",
  initialState: {
    data: {},
    dealers: [],
    showrooms: [],
    loading: false,
    showroomloading: false,
    error: {
      message: "",
      haveError: false,
    },
    active: {
      variantId: null,
      dealerId: null,
      showroomId: null,
    },
  },
  reducers: {
    modelLoadSuccess: (state, action) => {
      state.loading = false;
      state.error.haveError = false;
      state.data = action.payload;
    },
    modelLoadFailed: (state, action) => {
      state.loading = false;
      state.error = {
        message: action.payload.message,
        haveError: true,
      };
    },
    modelSetActive: (state, action) => {
      state.active[action.payload.type] = action.payload.data;
    },
    modelReplaceActive: (state, action) => {
      state.active = {
        ModelId: action.payload,
      };
    },
    modelDealersLoadSuccess: (state, action) => {
      state.loading = false;
      state.error.haveError = false;
      state.dealers = action.payload;
    },
    modelShowRoomsLoadSuccess: (state, action) => {
      state.showroomloading = false;
      state.error.haveError = false;
      state.showrooms = action.payload;
    },
  },
});

export const selectModelData = (state) => state.model.data || {};
export const selectModelDealers = (state) => state.model.dealer || [];
export const selectModelShowrooms = (state) => state.model.showroom || [];
export const selectActiveModel = (state) => state.model.active || {};
export const selectModelLoading = (state) => state.model.loading || false;
export const selectShowRoomLoading = (state) =>
  state.model.showroomloading || false;
const {
  modelLoadSuccess,
  modelLoadFailed,
  modelSetActive,
  modelReplaceActive,
  modelDealersLoadSuccess,
  modelShowRoomsLoadSuccess,
} = slice.actions;
export const loadModelFromAPI =
  (id = "", location, fun) =>
  async (dispatch) => {
    try {
      if (id.length > 0) {
        const data = await getModel({ id, location });
        if (data.status === "error") {
          fun.push("/404");
        }
        dispatch(modelLoadSuccess(data.data));
      }
    } catch (err) {
      dispatch(modelLoadFailed(err.toString()));
    }
  };

export const loadModelDealersFromAPI =
  (brand_id = "") =>
  async (dispatch) => {
    try {
      const data = await getDealers({ brand_id });
      dispatch(modelDealersLoadSuccess(data));
    } catch (err) {
      dispatch(modelLoadFailed(err.toString()));
    }
  };
export const loadModelShowRoomFromAPI =
  (dealer_id = "") =>
  async (dispatch) => {
    try {
      const data = await getShowrooms({ dealer_id });
      dispatch(modelShowRoomsLoadSuccess(data));
    } catch (err) {
      dispatch(modelLoadFailed(err.toString()));
    }
  };

export const loadModelDealerOnRoadPriceFromAPI =
  (id, variantId = "") =>
  async (dispatch) => {
    try {
      const data = await getOnRoadPriceForDealer({ id, variantId });
      dispatch(
        modelSetActive({
          type: "priceBreakup",
          data: data,
        })
      );
    } catch (err) {
      console.log("Error happened", err);
    }
  };

export const loadModelFromPreloadedData = (data) => async (dispatch) => {
  if (data) dispatch(modelLoadSuccess(data));
};

export const setActiveModel = (data) => async (dispatch) => {
  if (data) dispatch(modelSetActive(data));
};

export const resetActiveModel = (id) => async (dispatch) => {
  dispatch(modelReplaceActive(id));
};

export default slice.reducer;
