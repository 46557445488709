import React, { useState, useEffect } from "react";
import styles from "./CountDown.module.css";

const Countdown = ({ endDate }) => {
  const [daysRemaining, setDaysRemaining] = useState(null);

  useEffect(() => {
    const endDateTime = new Date(endDate);

    const now = new Date();
    const remainingDays = Math.ceil(
      (endDateTime - now) / (1000 * 60 * 60 * 24)
    );

    setDaysRemaining(remainingDays);
  }, [endDate]);

  return (
    <div>
      {daysRemaining !== null ? (
        daysRemaining < 0 ? (
          <p className={styles.offerText}>Offer Expired</p>
        ) : (
          <p className={styles.offerText}>
            {daysRemaining === 0
              ? `Offer Expires Today`
              : daysRemaining < 8
              ? `Offer Expires In ${daysRemaining} Day${
                  daysRemaining === 1 ? "" : "s"
                } `
              : null}
          </p>
        )
      ) : null}
    </div>
  );
};

export default Countdown;
