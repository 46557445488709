import React, { useEffect, useState, useCallback } from "react";
import { loadModelFromAPI, selectModelData } from "../model/modelSlice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Loading from "../../components/atoms/loading/Loading";
import { useParams } from "react-router-dom";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import { changeNumberFormat } from "../../utils";
import Image, { SimpleImage } from "../../components/atoms/Image/Image";
import styles from "./Exchange.module.css";
import { logout, selectUserData } from "../login/loginSlice";
import LoginPopUp from "../login/LoginPopUp";
import close from "../offers/SVG/close.svg";

const Exchange = () => {
  const { productId } = useParams();
  const location = useSelector(selectLocationData);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const userData = useSelector(selectUserData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loadModelFromAPI(
        productId,
        location?.lat &&
          location?.lng && {
            lat: location?.lat,
            lng: location?.lng,
          }
      )
    );
  }, [dispatch, productId, location.lat, location.lng]);

  const product = useSelector(selectModelData);

  const submitHandler = useCallback(() => {
    setApiCallSuccess(true);
  }, []);

  const LoginHandler = () => {
    setLoginForm(true);
  };

  useEffect(() => {
    if (loginForm && userData?.session && userData.session["access-token"]) {
      setLoginForm(false);
      submitHandler();
    }
  }, [userData, loginForm, submitHandler]);

  return (
    <>
      {product.id ? (
        <Container>
          {apiCallSuccess && (
            <div
              className={styles.modal}
              onClick={() => setApiCallSuccess(false)}
            >
              <span
                onClick={() => setApiCallSuccess(false)}
                className={styles.btn}
              >
                &times;
              </span>
              <div className={styles.content}>
                <div>
                  <p>
                    Thank You for contacting {product.dealer.name} -{" "}
                    {product.showroom.place}.
                  </p>
                  <p>We will contact you soon to assist you further.</p>
                </div>
                <div
                  onClick={() => setApiCallSuccess(false)}
                  className={styles.close}
                >
                  Okay
                </div>
              </div>
            </div>
          )}
          {loginForm && (
            <div className={styles.loginScreen}>
              <div className={styles.shade}>
                <div className={styles.closeContainer}>
                  <img
                    src={close}
                    alt="close"
                    loading="lazy"
                    className={styles.clearBtn}
                    onClick={() => {
                      setLoginForm(false);
                      dispatch(logout());
                    }}
                  />
                </div>
                <LoginPopUp />
              </div>
            </div>
          )}
          <Row>
            <Col md="5">
              <div className={styles.cont}>
                <Image
                  src={product.main_image}
                  alt={product.name}
                  className={styles.img}
                />
              </div>
            </Col>
            <Col md="1"></Col>
            <Col md="6">
              <div className={styles.cont_left}>
                <h1 className={styles.heading}>{product.name}</h1>
                <h2 className={`${styles.price} center`}>
                  {changeNumberFormat(product.price_starts)} to{" "}
                  {changeNumberFormat(product.price_ends)}
                </h2>

                {product?.showroom && product.dealer && (
                  <div className={styles.dealer}>
                    {product.dealer.logo && (
                      <div className={styles.dealerLogo}>
                        <SimpleImage
                          src={product.dealer.logo}
                          alt={product.dealer.name}
                        />
                      </div>
                    )}
                    <h2 className={styles.showroom}>
                      {product.dealer.name} - {product.showroom.place}
                    </h2>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          {location.lat && location.lng ? (
            <div className={styles.exchangeContainer}>
              <h3 className={styles.exHeading}>Exchange Car</h3>
              <Form className="my-5">
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Brand</Form.Label>
                    <Form.Control placeholder="Enter Brand Name" />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Model</Form.Label>
                    <Form.Control placeholder="Enter Model Name" />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Fuel Type</Form.Label>
                    <Form.Control as="select">
                      <option disabled>Select Fuel Type</option>
                      <option>Petrol</option>
                      <option>Diesel</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Kilometers Run</Form.Label>
                    <Form.Control />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Make Year</Form.Label>
                    <Form.Control />
                  </Form.Group>
                </Form.Row>
                <div className="text-center">
                  {userData?.session && userData.session["access-token"] ? (
                    <Button variant="primary" onClick={submitHandler}>
                      Submit
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={LoginHandler}>
                      Submit
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          ) : (
            <h4 className={styles.h4}>
              Please Enable Location For Exchange Process
            </h4>
          )}
        </Container>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Exchange;
