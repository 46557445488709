import React from "react"; // , { useState }
import styles from "./styles.module.css";
import {
  Accordion,
  Card,
  //  Dropdown
} from "react-bootstrap";
// import { changeNumberFormat } from "../../utils";

const Features = ({ variants, modelName }) => {
  const baseModal = variants
    .map((res) => res)
    .sort((a, b) =>
      a.ex_showroom_starting > b.ex_showroom_starting
        ? 1
        : b.ex_showroom_starting > a.ex_showroom_starting
        ? -1
        : 0
    );

  // const [selectedFeatures, setSelectedFeatures] = useState(
  //   baseModal[0].features
  // );
  // const [selectedFeatures, setSelectedFeatures] = useState(
  //   variants[0].features
  // );

  // const selectedFeatures = variants[0].features;

  // first varaints' feature
  const selectedFeatures = variants
    .map((res) => res)
    .sort((a, b) => {
      return parseInt(a.id) - parseInt(b.id);
    })[0].features;

  // const [selected, setSelected] = useState(baseModal[0]);
  // const [selected, setSelected] = useState(variants[0]);
  // const VariantSelected = (id) => {
  //   const SelectedVariant = variants.find((variant) => {
  //     return variant.id === id;
  //   });
  //   setSelectedFeatures(SelectedVariant.features);
  //   setSelected(SelectedVariant);
  // };

  const verify = (array, label) => {
    const value =
      array && array.length > 0 && array.some((res) => res.label === label);
    return value;
  };
  return (
    <>
      <div className={styles.feature}>
        <div className={styles.variants}>
          <h2 className={styles.heading}>{`${modelName} Variants`}</h2>

          {variants &&
            baseModal.map((variant) => (
              <p
                className={styles.variantName}
                // className={
                //   selected.id === variant.id
                //     ? `${styles.variantName} ${styles.selected}`
                //     : styles.variantName
                // }
                key={variant.id}
                // onClick={() => VariantSelected(variant.id)}
              >
                {/* {variant.name} */}
                {variant.name}
                {/* <span className={styles.amount}>
                  {" "}
                  {changeNumberFormat(variant.ex_showroom_starting)}
                </span> */}
              </p>
            ))}
        </div>
        {/* <Dropdown className={styles.mobile} drop="down">
          <Dropdown.Toggle variant="light" id="dropdown-autoclose-true">
            {selected.name ? `${selected.name}` : " Choose Variant"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              {variants &&
                baseModal.map((variant) => (
                  <p
                    className={
                      selected.id === variant.id
                        ? `${styles.variantName} ${styles.selected}`
                        : styles.variantName
                    }
                    key={variant.id}
                    onClick={() => VariantSelected(variant.id)}
                  >
                    {variant.name}
                  </p>
                ))}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        <div className={styles.featureDescription}>
          <h2 className={styles.heading}>{`${modelName} Specifications`}</h2>

          <Accordion defaultActiveKey="0">
            {verify(selectedFeatures, "engine_and_transmission") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <h2 className={styles.classification}>
                    Engine And Transmission
                  </h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"engine_and_transmission"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "fuel_and_performance") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  <h2 className={styles.classification}>
                    Fuel And Performance
                  </h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"fuel_and_performance"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "suspension") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  <h2 className={styles.classification}>Suspension</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"suspension"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "dimension") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  <h2 className={styles.classification}>Dimension</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"dimension"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "comfort") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  <h2 className={styles.classification}>Comfort</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"comfort"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "interior") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  <h2 className={styles.classification}>Interior</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"interior"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "exterior") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  <h2 className={styles.classification}>Exterior</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"exterior"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "safety") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  <h2 className={styles.classification}>Safety</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"safety"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
            {verify(selectedFeatures, "entertainment") && (
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="8">
                  <h2 className={styles.classification}>Entertainment</h2>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    <Feature
                      selectedFeatures={selectedFeatures}
                      factor={"entertainment"}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

const Feature = ({ selectedFeatures, factor }) => {
  return (
    <div>
      {selectedFeatures &&
        selectedFeatures.length > 0 &&
        selectedFeatures.map((feature) =>
          feature.label === factor ? (
            <p
              className={styles.featureContent}
              key={feature.feature_id || feature.id}
            >
              <span className={styles.firsthalf}> {feature.name} </span>
              <span className={styles.secondhalf}>: {feature.value}</span>
            </p>
          ) : (
            ""
          )
        )}
    </div>
  );
};

export default Features;
