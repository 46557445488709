import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, logout } from "../login/loginSlice";
import { selectModels, fetchModels, fetchBrands } from "../home/homeSlice";
import get from "lodash/get";
import styles from "./Header.module.css";
import logo from "./SVG/logo.png";
import logoImg from "./SVG/logoImg.png";
import search from "./SVG/search.svg";
import close from "./SVG/close.svg";
import searchIcon from "./SVG/searchIcon.svg";
// import logoutIcon from "./SVG/logout.svg";
import Modal from "react-bootstrap/Modal";
import { ImageWithoutWrapper } from "../../components/atoms/Image/Image";
import Location from "../../components/molecules/Location/Location";
const Header = () => {
  const models = useSelector(selectModels);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const searchInputRef = useRef();

  useEffect(() => {
    if (show) {
      searchInputRef.current.focus();
    }
  }, [show]);

  const handleShow = () => {
    setShow(true);
  };

  const logoutUser = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(fetchModels());
    dispatch(fetchBrands());
  }, [dispatch]);

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = models.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setShow(false);
  };

  return (
    <>
      <nav className={styles.navbar}>
        <Link to="/">
          <div className={styles.logo}>
            <img src={logoImg} alt="CarBae Logo" loading="lazy" />
            <img src={logo} alt="CarBae Logo" loading="lazy" />
          </div>
        </Link>
        <div className={styles["nav-right"]}>
          <img
            src={search}
            className={styles.headerSearch}
            alt="search"
            loading="lazy"
            onClick={() => handleShow()}
          ></img>
          <Location />

          {get(userData, ["session", "access-token"]) ? (
            <a className={styles.navcontent} href="/" onClick={logoutUser}>
              Sign Out{" "}
            </a>
          ) : (
            <Link className={styles.navcontent} to="/login">
              Sign In{" "}
            </Link>
          )}
        </div>
      </nav>
      <Modal
        show={show}
        size="xl"
        dialogClassName={styles.modalClass}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <div className={styles.searchBar}>
            <div className={styles.searchIcon}>
              <img src={searchIcon} alt="searchIcon" loading="lazy" />
            </div>
            <input
              autoFocus
              ref={searchInputRef}
              type="text"
              placeholder="Search"
              value={wordEntered}
              onChange={handleFilter}
            />
            <img
              src={close}
              alt="clear"
              loading="lazy"
              className={styles.clearBtn}
              onClick={clearInput}
            />
          </div>
        </Modal.Header>
        {filteredData.length !== 0 && (
          <Modal.Body>
            <>
              {filteredData.slice(0, 15).map((value, key) => {
                return (
                  <Link
                    key={value.id}
                    className={styles.searchResult}
                    to={`/${value.slug}`}
                    onClick={clearInput}
                  >
                    <div className={styles.top}>
                      <ImageWithoutWrapper src={value.main_image} />
                    </div>
                    <p className="center">{value.name} </p>
                  </Link>
                );
              })}
            </>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default Header;
