import { createSlice } from "@reduxjs/toolkit";
import { getBrands, getModels, getBanners } from "../../api/models";

export const slice = createSlice({
  name: "home",
  initialState: {
    models: [],
    brands: [],
    banners: [],
    loadingModels: true,
    loadingBrands: true,
    error: {
      message: "",
    },
  },
  reducers: {
    getModelsSuccess: (state, action) => {
      state.models = action.payload;
      state.loadingModels = false;
      state.error = {};
    },
    getBannerSuccess: (state, action) => {
      state.banners = action.payload;
      state.loadingModels = false;
      state.error = {};
    },
    getBannerFailed: (state) => {
      state.banners = [];
      state.loadingModels = false;
      state.error.message = "Something went wrong";
    },
    getModelsFailed: (state) => {
      state.models = [];
      state.loadingModels = false;
      state.error.message = "Something went wrong";
    },
    getBrandsSuccess: (state, action) => {
      state.brands = action.payload;
      state.loadingBrands = false;
      state.error = {};
    },
    getBrandsFailed: (state) => {
      state.brands = [];
      state.loadingBrands = false;
      state.error.message = "Something went wrong";
    },
  },
});

export const selectModels = (state) =>
  // state.home.models.filter((model) => model.brand_id !== 32) || [];
  state.home.models || [];
export const selectBrands = (state) =>
  // state.home.brands.filter((brand) => brand.id !== 32) || [];
  state.home.brands || [];
export const selectBanners = (state) => state.home.banners || [];

export const selectNewReleases = (state) =>
  state.home.models.filter((model) => !!model.is_released) || [];
export const selectTopModels = (state) =>
  state.home.models.filter((model) => !!model.is_trending) || [];

export const selectElectricModels = (state) =>
  state.home.models
    .filter((model) => model.fuel_types === "electric")
    .sort((a, b) =>
      a.price_starts > b.price_starts
        ? 1
        : b.price_starts > a.price_starts
        ? -1
        : 0
    ) || [];

export const bodyTypes = (state) => [
  ...new Set(
    state.home.models && state.home.models.map((model) => model["body_type"])
  ),
];
export const transmissionTypes = (state) => [
  ...new Set(
    state.home.models &&
      state.home.models.map((model) => model["transmission_types"])
  ),
];
export const fuelTypes = (state) => [
  ...new Set(
    state.home.models && state.home.models.map((model) => model["fuel_types"])
  ),
];

export const {
  getModelsSuccess,
  getModelsFailed,
  getBrandsSuccess,
  getBrandsFailed,
  getBannerSuccess,
  getBannerFailed,
} = slice.actions;
export const fetchModels = () => async (dispatch) => {
  try {
    const modelsDetails = await getModels();
    dispatch(getModelsSuccess(modelsDetails));
  } catch (err) {
    dispatch(getModelsFailed(err.toString()));
  }
};

export const fetchBanners = () => async (dispatch) => {
  try {
    const banner = await getBanners();
    // console.log(banner);
    dispatch(getBannerSuccess(banner));
  } catch (err) {
    dispatch(getBannerFailed(err.toString()));
  }
};

export const fetchBrands = () => async (dispatch) => {
  try {
    const brandsDetails = await getBrands();
    dispatch(getBrandsSuccess(brandsDetails));
  } catch (err) {
    dispatch(getBrandsFailed(err.toString()));
  }
};

export default slice.reducer;
